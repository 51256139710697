<template>
  <div class="section1">
    <div class="bg relative">
      <div v-if="!isMobile">
        <img src="./s1/bg.jpg" alt class="img"/>
        <img src="./s1/bg2.jpg" alt class="img fix mix" />
        <img src="./s1/bg3.png" alt class="img bottom_img"/>
        <img src="./s1/img1.png" alt class="img fix" />
        <!-- <img src="./s1/logo.png" alt="頤昌豐岳" class="img" data-aos="fade" data-aos-delay="700" /> -->
        <!-- <icon class="icon logo-icon" :data="logo" /> -->
        <div class="logo-icon">
<svg version="1.1" id="svg1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1920 1080" style="enable-background:new 0 0 1920 1080;" xml:space="preserve">
<pattern  width="165.3" height="231.9" patternUnits="userSpaceOnUse" id="img_3" viewBox="0 -328.9 165.3 231.9" style="overflow:visible;">
	<g>
		<rect x="0" y="-328.9" class="st0" width="165.3" height="231.9"/>
		
			<image style="overflow:visible;" width="500" height="580" xlink:href="./s1/logobg1.jpg"  transform="matrix(0.3306 0 0 0.3998 1.718709e-05 -231.8918)">
		</image>
		
			<image style="overflow:visible;" width="500" height="580" xlink:href="./s1/logobg1.jpg"  transform="matrix(0.3306 0 0 0.3998 1.718709e-05 -463.7837)">
		</image>
	</g>
</pattern>
<pattern  width="170" height="184.6" patternUnits="userSpaceOnUse" id="img_2" viewBox="14.7 -299.6 170 184.6" style="overflow:visible;">
	<g>
		<rect x="14.7" y="-299.6" class="st0" width="170" height="184.6"/>
		
			<image style="overflow:visible;" width="500" height="543" xlink:href="./s1/logobg2.jpg"  transform="matrix(0.34 0 0 0.34 170 -184.62)">
		</image>
		
			<image style="overflow:visible;" width="500" height="543" xlink:href="./s1/logobg2.jpg"  transform="matrix(0.34 0 0 0.34 0 -184.62)">
		</image>
		
			<image style="overflow:visible;" width="500" height="543" xlink:href="./s1/logobg2.jpg"  transform="matrix(0.34 0 0 0.34 170 -369.24)">
		</image>
		
			<image style="overflow:visible;" width="500" height="543" xlink:href="./s1/logobg2.jpg"  transform="matrix(0.34 0 0 0.34 0 -369.24)">
		</image>
	</g>
</pattern>
<g>
	<defs>
		<path id="SVGID_1_" d="M662.4,204.7c-0.7-1.7-1.9-3.4-3.3-4.6c-4.7-3.9-10.3-5.4-16.1-6.4c-0.7-0.1-2,0.5-2.3,1.1
			c-0.6,1.2-1.3,1.3-2.4,1.3c-6.1,0-12.3,0-18.4,0c-2.2,0-4.3-0.1-6,1.5c-0.2,0.2-0.7,0.3-1,0.3c-3.5,0-7,0-10.5,0
			c5.9-1.3,11.9-2.2,17.7-4c5.5-1.8,10.7-3.3,16.7-2.7c3.5,0.4,6.9-1.4,8-5.3c1.3-4.4,2.5-8.7,3.5-13.1c0.6-2.9,0.9-5.9,1.2-8.8
			c0.2-2,0.2-4.1,0.2-6.2c0-1.7-0.2-3.4-0.2-5.1c0-2.5,0.5-5,0.3-7.4c-0.4-6-1.1-12-1.7-17.9c-0.2-2.4-0.3-4.9-1.9-6.9
			c-3-3.9-8.6-5.3-13.4-3.8c-2,0.6-2.3,1.7-2.2,3.4c0.1,1.5,0,3,0,4.8c-1.1-0.7-2-1-2.5-1.6c-1.8-2.3-4.5-3.1-7.1-3.9
			c-1-0.3-2-0.8-2.9-1.3c-0.5-0.3-1.2-0.8-1.4-1.3c-1.7-4.1-5.1-6.5-8.5-8.9c-1.6-1.1-2.3-0.7-2.5,1.2c-0.2,2.2,0,4.5-0.2,6.7
			c-0.4,5-0.8,5.6-4.7,6.9c-1,0.3-1.8,0.8-2.8,1.2c-1.9,0.8-3.1,0.6-5.4,1.5c-0.3-9.5-3.8-14.7-4.3-22.5c-0.2-2.6-2.1-4.2-4.6-4.8
			c-2.3-0.5-3.8,1.1-5,2.8c-1.9,2.7-2,5.9-2.8,8.9c-1.4,5.6-2.4,11.1-2.3,16.9c0.1,10.8,0,21.7,0,32.5c0,0.9-0.2,1.8-0.2,2.9
			c-0.5-0.2-0.6-0.3-0.8-0.4c-4.4-3.6-9-6.6-14.7-7.4c-0.6-0.1-1.6-1.1-1.6-1.8c-0.1-1.4,0.3-2.9,0.6-4.4c0-0.1,0.4-0.2,0.6-0.2
			c2-0.4,3.9-0.8,5.9-1.1c3-0.5,4.8-2.3,6.3-4.8c1.2-2-0.8-2.6-1.3-3.8c-0.6-1.3-1.8-2.5-3-3.2c-2.3-1.3-4.8-2.2-7.2-3.1
			c-1.2-0.5-1.9-1-2.4-2.3c-1.5-3.3-3.4-6.5-5.2-9.7c-0.4-0.7-1.1-1.1-2-1.8c-0.3,1.1-0.6,1.7-0.8,2.4c-0.6,3-1.3,5.9-1.7,8.9
			c-0.4,2.6-1.7,4.3-3.9,5.4c-2.5,1.3-5,2.6-7.5,3.9c-1.8,0.9-2.4,2.5-1.3,3.8c1.3,1.5,2.8,2.8,5,2.9c0.9,0,1.8,0.6,2.7,1
			c-0.4,0.8-0.6,1.8-1.1,2.5c-2.5,3.6-5.2,6.9-7.6,10.6c-2.3,3.6-1.3,5.7,2.9,6.6c0.7,0.1,1.5,0.3,2,0.7c1.7,1.5,3.6,1.3,5.6,1.1
			c0.6-0.1,1.1-0.2,1.6-0.3c0.1,0.1,0.2,0.3,0.3,0.4c-1,1.4-2,2.7-3.1,4.1c-1.1,1.5-2.4,3-3.4,4.7c-1.2,2.2-0.8,3.4,1.3,4.6
			c3.3,2,6.8,3.3,10.8,3.4c3,0,4.4,1.3,5.1,4c-6.1,1.2-12.2,1.2-18.9,0.4c1.8-2.2,1-3.9,0.2-5.6c-0.6-1.1-1.2-2.3-1.8-3.4
			c-2.4-4.2-4.1-8.6-4.8-13.4c-0.4-2.9-0.7-5.8-1.4-8.6c-2.7-10.5-2.1-20.9,0.6-31.3c0.3-1.3,0.4-2.6,0.4-4c-0.1-1.4-1-2.5-2.4-2.5
			c-0.8,0-1.7,0.8-2.4,1.4c-0.6,0.6-0.8,1.5-1.2,2.2c-4.1,9.2-5.8,18.6-4.4,28.7c1.3,9.6,2.7,19.1,7.3,27.6
			c2.3,4.3,2.9,8.1,0.7,12.4c-0.2,0.4-0.3,1.1,0,1.5c1.1,1.7,2.4,3.1,4.4,3.4c3.1,0.5,6.1,1,9.2,1.2c4.9,0.3,9.8,0.3,14.7,0.4
			c9.5,0.1,18.9,0.1,28.4,0.1c0.7,0,1.4,0.1,2.1,0.1c-0.8,0.5-1.6,0.5-2.3,0.6c-20.5,2.8-40.9,6.8-60.9,12.2
			c-7.7,2.1-15.3,4.6-23,6.6c-1.8,0.5-3,1.1-3.7,2.8c-0.5,1.1-0.3,1.7,0.8,2.1c2.6,1,5.2,1.8,8,1.1c5.7-1.5,11.5-3.2,17.2-4.7
			c12.8-3.4,25.5-7.6,38.7-9.2c4.2-0.5,8.1-2.6,12.3-3.7c10.8-2.7,20.2-3.8,31.3-5.1c2.4-0.3-12.8,5.1-23.5,8.8
			c-8.3,2.9-16.5,6.3-24.7,9.4c-1.4,0.5-2.9,0.9-4.4,1.3c-1.5,0.4-3.2,0.4-4.5,1.2c-1.2,0.8-2,2.4-3,3.7c-0.1,0.1-0.2,0.3-0.2,0.5
			c-0.3,5.2,0.3,10,4.7,13.5c3,2.4,6.1,4.9,10.4,5c-0.7,0.9-1.4,1.5-2.1,2.2c-1.1,1-2.6,1.8-3.2,3.1c-1,2.1-2.5,3.4-4.4,4.6
			c-4.4,2.8-4.6,6-0.9,9.8c0.7,0.7,1.4,1.6,2.2,2.5c-2.6,1.6-5.2,1.8-7.8,2.1c-3.7,0.4-7.4,0.8-11.1,1.6c-2.7,0.5-5.2,1.6-7.8,2.4
			c-2.9,0.9-5.8,1.6-8.7,2.6c-0.7,0.2-1.7,1.5-1.5,1.8c0.9,1.9,1.3,4.4,3.5,5.5c4.2,2,8.6,3.2,13.2,2.2c3-0.7,5.8-2.2,8.8-2.9
			c9.9-2.5,19.9-5,29.8-7.3c1.9-0.5,4-1,5.8-0.5c1.7,0.4,3,0,4.4-0.4c0.9-0.3,1.7-0.8,2.6-1.2c3.1-1.3,6.2-2.2,10.1-1.8
			c-0.9,0.5-1.3,0.7-1.8,1c-0.4,0.3-0.7,0.6-1.1,0.8c5.8-2,11.8-2.1,17.7-2.3c5.9-0.2,11.5,1,17.1,2.7c0,0.2,0,0.3-0.1,0.5
			c-6.6,2.7-13.2,5.4-19.8,8c0,0.2,0.1,0.4,0.1,0.6c1.5,0,3,0,4.9,0c-1.4,0.7-2.6,1.1-3.5,1.9c-1.2,1-2.2-0.2-3.2,0.1
			c-3,0.9-6,1.9-9,2.8c0.7,0.5,1.3,0.8,2,0.8c2.2,0,4.3-0.1,6.5-0.1c6.3,0,12.5,0.7,18.7-1c2.8-0.8,5.8-1.4,8.7-1.6
			c4.7-0.3,9.5-0.1,14.3-0.5c2.7-0.2,5.4-1.1,7.9-2.1c2-0.8,2.4-2.5,1.3-4.3c-0.8-1.4-1.9-2.7-2.9-3.9c-4.5-4.9-10.2-7.9-16.4-9.6
			c-6.4-1.8-13-2.8-19.5-4.1c-0.6-0.1-1.1-0.4-1.4-0.5c2.6-1.9,5.2-3.6,7.6-5.6c3-2.5,3.4-5.5,0.9-8.6c-4.1-5.2-9.2-8.6-16-8.8
			c-0.9,0-1.8-0.3-2.6-0.4c0-0.2,0-0.4-0.1-0.5c0.5-0.4,0.9-0.9,1.4-1.2c1.7-1.2,3.4-2.4,5.2-3.6c2.6-1.8,5.1-3.7,7.9-5.4
			c2.8-1.7,3.3-3.2,1.5-6c-0.6-0.9-1.3-1.8-2.1-2.4c-2.3-1.8-4.6-3.5-6.9-5.1c-4.9-3.4-10-3.3-15.2-0.5c-4.2,2.2-8.4,4.3-12.6,6.5
			c-0.8,0.4-1.4,0.9-2.6,1.7c0.9,0.7,1.4,1.2,2,1.7c0.6,0.5,1.3,1,2.2,1.6c-6.3,5.4-12.3,10.7-18.3,16c-1.1-2.2-2-4-2.8-5.8
			c-1.3-2.6-1-3.8,1.3-5.4c1.4-1,3.1-1.7,4.5-2.7c1.2-0.9,2.3-2,3.3-3.1c1.7-1.7,3.3-3.5,5-5.1c0.4-0.4,1-0.5,1.6-0.7
			c5.5-1.8,11-3.6,16.5-5.5c11-3.7,21.9-7.2,33.8-5.3c5.3,0.8,10.8,0.6,16.2,0.9c3.8,0.2,7.6,0.5,11.2,2.1c0.9,0.4,1.8,0.5,2.8,0.6
			C661.5,210.6,663.7,207.9,662.4,204.7z M618.4,138.1c0.2-1.9,1.2-3,3-3.3c2.3-0.4,4.7-0.7,7.6-1.1c-0.4,5.1-0.8,9.6-1.3,14.6
			c-0.9-0.6-1.5-1-2.1-1.4c-1.8-1.2-3.4-2.6-5.3-3.7C618.4,142,618.2,140.1,618.4,138.1z M617.3,159c2.3-0.7,4.5-1.3,6.6-2
			c1.7-0.6,2.6,0.3,2.6,1.7c0,2.4-0.3,4.8-0.7,7.1c-0.2,1.1-1.2,1.4-2.3,1c-1.7-0.7-3.5-1.2-5.1-2c-0.6-0.3-1.3-1.3-1.4-2
			C616.8,161.6,617.1,160.3,617.3,159z M612.3,179.6c0.3-1.1,0.8-2.2,1-3.3c0.2-1.3,0.5-2.8,1.9-2.9c1.4-0.1,2.8,0.3,4.2,0.7
			c0.4,0.1,0.7,0.6,1.1,0.8c0.5,0.4,1.1,0.7,1.6,1.1c-0.5,0.5-0.8,1.2-1.4,1.5c-2.9,1.7-5.8,3.2-8.7,4.8c-0.1-0.1-0.2-0.2-0.4-0.4
			C612,181.2,612.1,180.4,612.3,179.6z M573.9,186.1c0,1.6-0.5,2.9-2.4,2.8c-3.6-0.1-7.2-0.1-10.8-0.2c-0.4,0-0.7,0-1.4,0
			c0.2-0.6,2.6-1.5,2.5-2.1c-0.2-1.2-0.4-2.4-0.8-3.5c-0.8-2.1,0.4-2,1.9-2.9c0.2-0.1,1.9-0.7,2.4-0.8c0.6-0.2,4.8-1.4,5.2-1.5
			c0.4-4.6-4.1-6.2-5.9-6.3c-1.4,0.2-3.5-0.2-5.2,0.2c-1.4,0.4-1.9,0-2-1.4c-0.1-0.8-0.1-0.6,0-1.4c0.1-1.9,0.7-2.1,2.3-1.2
			c0.3,0.2,0.7,0.4,1,0.3c3.8-1.5,7.6,0,11.5-0.2c0.4,0,1.1,0.9,1.2,1.5c0.4,1.5,0.6,3.1,0.7,4.7C574,178.2,573.9,182.2,573.9,186.1
			z M594.6,129.9c2.9-0.3,5.8-0.2,8.7,0c0.4,0,1.3,1.6,1.1,2.2c-0.5,1.4-1.3,7.4-2.4,8.4c-2.8,2.6-4.1,2.6-8.6,2.9
			c-0.2-0.1-0.3-0.2-0.5-0.3c0-2.6-0.1-4.3-0.1-6.9c0-0.9,0-2.1,0-3.4C592.6,130.2,593.3,130,594.6,129.9z M597.8,177.2
			c-0.6,2.2-0.7,6.9-1.2,7.8c-0.8,1.3-4,2.1-5.8,1.4c-1.9-0.7-1.7-2.3-1.4-3.8c0.6-2.9,1.1-6.4,1.8-9.3c0.3-1.3,1-1.7,2.2-1.2
			c0.8,0.3,1.8,1.1,2.6,1.4C598.1,174.4,598.5,174.9,597.8,177.2z M601.8,158c-0.9,1.2-1.2,4.8-2.3,5.7c-2.2,1.9-4.9,0.4-7.5,1.3
			c0.5-4.2,0.6-5.8,1-9.4c0.2-1.6,1-2,2.5-1.5c1.8,0.6,3.7,1.1,5.6,1.7C602.2,156.1,602.5,157,601.8,158z M594.1,248.8
			c-5.4,4.4-11.6,7.4-17.8,10.4C581.3,254.2,586.7,249.8,594.1,248.8z"/>
	</defs>
	<clipPath id="SVGID_2_">
		<use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
	</clipPath>
	<g class="st0">
		<path class="st1 ld1" d="M583,96.3c2,34-0.9,87.9-0.9,87.9"/>
		<path class="st2 ld2" d="M538,141.9c9-2,16.4-1.7,25.6-3.4c-19.6,3.4-21.6,13.4-26,23.8c9.8-0.7,19.4-1.3,32.4,2.7
			c-16-1-17.5,9.1-27,14c9.2-1.6,14.1-6.2,23.4-6.3"/>
		<path class="st3 ld3" d="M549.6,121.8c3,15.5,5.4,56.6,7.4,61.6"/>
		<path class="st4 ld4" d="M596.4,125.7c12.9-0.7,16.1-3.6,28.8-1.6c-5.5,2.7,3.8,4.7-0.4,5.5C613,132,605.2,146.3,594,149"/>
		<path class="st5 ld5" d="M594,147c12.5,2.6,15.1,0.4,25.4,3.6"/>
		<path class="st4 ld6" d="M620.7,151.7c-13.4,0.4-22.9,17.5-27.5,19c10.1-1.7,17-5.7,28.8,0.2"/>
		<path class="st2 ld7" d="M608.6,111.7c6.4,15.9,0.8,58.3-4.8,70.6"/>
		<path class="st2 ld8" d="M529.3,117.7c-6.1,8.4-12.6,47.5,1.8,69c-5.3,10.4,77.2,7,108.2-4.3"/>
		<path class="st6 ld9" d="M635.3,102.8c4.3,13.5,6.5,67.9-2.9,75.1"/>
		<path class="st7 ld10" d="M496.4,221.9c29.7-6.4,136-46.7,163.9-15.8c-79.5-17.1-93.7,17.4-105.5,22.5"/>
		<path class="st7 ld11" d="M548.8,225.6c-3.7,9.5,9.4,13.3,9.4,13.3"/>
		<path class="st7 ld12" d="M573.9,219.4c23-5.3,32-2.3,39.5,2.3"/>
		<path class="st8 ld13" d="M604.4,222.7c-22.3,12.3-24.6,24.1-39.8,29.4"/>
		<path class="st8 ld14" d="M556.9,257.8c3.8,5.2,10.3,6.3,10.3,6.3"/>
		<path class="st9 ld15" d="M585.2,241.9c26.6-9.7,53.9,1.6,0.8,20.1"/>
		<path class="st10 ld16" d="M520.4,281.4c41.7-8.6,103.3-29.2,127.9-2.4c-14.1-0.5-41.7,4.4-49.7,8.7"/>
	</g>
</g>
<g>
	<defs>
		<path id="SVGID_3_" d="M664.1,399c-3.6-6-6.6-12.5-13.2-15.9c-2-1-4.2-1.8-6.3-2.7c-1.1-0.4-2.4-0.5-3.4-1.1
			c-2.9-1.7-6-2.1-9.4-1.9c-7.1,0.5-14.2,0.3-21.3,0.4c-0.6,0-1.1,0-1.9,0c0.9-0.7,1.6-1.1,2.2-1.6c3.6-3,7.3-5.9,10.7-9.2
			c1.2-1.2,2.2-3,2.5-4.7c0.7-4.3-0.9-8-4.2-10.6c-3.5-2.8-7.3-5.1-11-7.6c-0.7-0.5-1.5-0.9-2.3-1.4c0.1-0.3,0.2-0.5,0.2-0.6
			c0.2-0.2,0.4-0.5,0.6-0.7c2-2.4,4.7-3,7.6-3.6c1.8-0.4,3.8-0.7,5.4-1.6c5.1-2.8,7.4-7.8,9.3-13c0.6-1.7,0.5-3.8,0.2-5.6
			c-1.1-6.3-4.7-11.3-9.1-15.6c-1.4-1.3-3.6-2.1-5.5-2.5c-3.6-0.7-7.3-0.2-10.7,1.1c-4.6,1.8-9.2,3.6-13.7,5.5
			c-0.2,0.1-0.4,0.4-0.6,0.6c-0.9,1-1.6,2.2-2.7,2.9c-3.3,2-6.8,3.9-10.3,5.8c-0.4,0.2-1,0.1-1.5,0.2c0-0.5-0.1-1-0.1-2.1
			c-5,3.1-9.7,6.1-14.5,9.1l0.2,0.4c0.9-0.4,1.8-0.9,2.7-1.3c-0.3,0.7-0.8,1.1-1.4,1.5c-4,2.8-8,5.5-11.9,8.5
			c-3.5,2.7-6.6,5.8-10.1,8.5c-2.3,1.8-5,3-7.5,4.5c0-0.1-0.1-0.1-0.1-0.2c0.4-0.5,0.7-1,1.1-1.5c-0.1-0.1-0.3-0.2-0.4-0.4
			c-2.4,2.7-4.8,5.4-7.2,8.1c-1.2,1.3-1.1,2.4,0,3.7c1.2,1.5,2.1,3.1,3.3,4.7c-2.1,0.6-1.9,1.8-1.5,3.1c0.3,1.3,0.7,2.5,0.7,3.8
			c0.1,2.6,0,5.1,0,7.7c0,5.5,0.9,10.9,2.9,16.1c0.6,1.4,0.8,3,1.1,4.5c0.1,0.5-0.1,1.4-0.5,1.7c-2.2,1.6-4.4,3.3-6.8,4.5
			c-5.5,2.5-11.2,4.6-16.8,7c-2.2,0.9-4.5,1.9-6.5,3.2c-2.8,1.8-5.4,3.8-8,5.9c-0.6,0.5-1.3,1.5-1.2,2.3c0,2.7,1,5,3.7,6.2
			c0.6,0.3,1.2,0.7,1.5,1.2c1,2,2.9,2,4.7,2.2c1.5,0.1,3.1,0.1,4.5,0.4c2.9,0.5,4.2-0.1,5.3-2.8c0.2-0.6,0.6-1.2,1.2-2.3
			c-3.5,0.8-7.8,1.7-7.9,0.8c-0.1-0.9,1.1-1.4,2.8-2.2c7.6-3.8,15.1-7.5,22.8-11.1c2.7-1.3,5.6-2.4,8.4-3.5c0.6-0.2,1.3-0.2,2-0.3
			l0.1,0.4c-8.4,4.9-16.9,9.7-25.3,14.6c0.1,0.2,0.2,0.4,0.3,0.6c0.7-0.2,1.4-0.5,2.1-0.7c1.3-0.4,2.6-0.7,3.9-1.1
			c1.6-0.5,3.4-0.6,4.7-1.5c4.7-3.7,10.4-5.4,15.5-8.5c2.5-1.5,3-1.4,5.1,0.7c3.8,3.8,7.7,7.4,12.5,9.9c0.6,0.3,1.4,0.3,2.1,0.4
			c-0.1-0.8-0.1-1.7-0.4-2.5c-0.2-0.5-0.9-0.8-1.3-1.2c-0.5-0.5-0.9-1.1-1.3-1.7c-0.6-0.9-1.1-1.9-1.7-2.9c-0.5-0.8-1.1-1.5-1.7-2.2
			c2,0.8,2.7,4.1,6,3c-0.5-0.9-0.8-1.6-1.2-2.1c-0.9-1.4-1.7-2.8-2.8-4.1c-3.1-3.9-3.1-3.8,1.4-6c1.3-0.6,2.4-1.5,3.6-2.2
			c-0.1-0.3-0.2-0.6-0.3-0.9c-1.9,0.5-3.7,1-5.6,1.5c0-0.2-0.1-0.4-0.1-0.6c6.3-2.5,13.1-2.7,19.9-4c-0.2,0.5-0.3,0.9-0.5,1.2
			c-1.9,3.8-3.9,7.5-5.7,11.3c-1.6,3.3-2.6,6.7-2.4,10.5c0.2,3.3,0,6.7,0,10c0,0.5,0.1,1.2-0.2,1.4c-1.7,1.2-3.4,2.3-5.2,3.4
			c-0.7,0.4-1.6,0.4-2.3,0.8c-2.4,1.3-4.7,1.4-7.1-0.1c-0.5-0.3-1.1-0.7-1.7-0.9c-4.6-1.2-9.2-2.6-13.8-3.3
			c-6.1-0.9-11.1,1.8-15.2,6.3c-3.3,3.6-5,8.1-6.8,12.5c-0.6,1.5-0.5,3.4-0.2,5.1c0.7,4.2,3,7.4,6.1,10.2c1.4,1.2,3.2,2.1,4.2,3.6
			c2.2,3.2,5.4,5.4,8.9,4.3c3.6-1.1,7.4-1.8,11-3c3.7-1.1,6.7-4.4,10.4-5.7c2.7-0.9,5.4-1.7,8.1-2.8c2.7-1.1,5.3-2.5,8-3.6
			c6-2.5,12-5.1,18.8-5.3c3.7-0.1,5-1.7,5.1-5.5c0-3,1.2-5.3,3-7.4c3.8-4.2,7.7-8.3,11.8-12.6c-3.9,2.3-7.8,4.5-11.6,6.7
			c2.1-2.7,4.5-4.9,7.8-5.8c4.5-1.2,8.9-2.4,13.4-3.6c0.7-0.2,1.4-0.3,2.1-0.5c1.1-0.3,1.6,0.2,1.6,1.2c-0.1,6.4-0.7,12.8-3.3,18.7
			c-2.1,4.7-4.4,9.3-6.7,14c-0.2,0.4-0.7,0.9-1.1,0.9c-2.5-0.2-5.1-0.4-7.2-2.2c-0.5-0.4-1-0.7-1.5-1c-0.1,0.1-0.2,0.2-0.3,0.3
			c1.1,1.5,2.1,3,3.2,4.5c-0.1,0.1-0.2,0.2-0.4,0.3c-2.5-2.7-5-5.5-7.5-8.2c0.7,1.6,1.5,3.1,2.6,4.4c4.8,5.6,9.7,11.1,14.6,16.7
			c1.1,1.2,2.1,2.5,3,3.8c2.5,3.6,5.2,6.9,9.8,8.1c2.1,0.5,3.9,0.4,5.6-0.8c3.1-2.2,6.2-4.5,7.4-8.4c0.4-1.5,1.5-2.7,2.1-4.1
			c1.4-3,3.1-5.9,3.9-9.1c2.8-10.3,3.3-20.8,0.1-31.2c-2.9-9.4-9-15.8-18.2-19.5c-3-1.2-5.4,0.6-8.1,0.8c-0.2,0-0.4,0.3-0.5,0.5
			c-0.7,2.3-2.6,3.3-4.4,4.5c-5.5,3.8-11.2,7.5-16.8,11.2c-1.1,0.7-2.2,1.4-3.4,2.2c-0.4-2.1-0.6-4-1.3-5.8c-0.9-2.3-1-4.7,0-6.8
			c1.6-3.3,1.5-6.6,0.6-9.9c-1.6-5.6-4.5-10.4-10.2-12.8c-0.4-0.2-1-0.7-1-1.1c0-0.3,0.6-0.8,1-1c0.6-0.3,1.2-0.6,1.8-0.7
			c4.9-0.7,9.8-1.7,14.8-2.1c5.2-0.4,10.5-0.1,15.7,0c0.7,0,1.3,0.1,2,0.2c0.1,0.2,0.2,0.3,0.3,0.5c-1.8,2.1-3.3,4.5-5.3,6.3
			c-2.6,2.3-5.7,4.1-8.5,6.2c-0.7,0.5-1.4,1-2.1,1.5c0.1,0.1,0.1,0.2,0.2,0.3c1.1-0.3,2.2-0.6,3.3-0.9c0,0.1,0.1,0.2,0.1,0.3
			c-2.4,1.5-4.8,3-7.2,4.6c0.8,0.1,1.4-0.1,2-0.1c5.1-0.1,10.1-0.1,15.2-0.3c1.5-0.1,3.3-0.3,4.5-1.1c2.2-1.4,4.5-1.6,6.9-1.3v1.4
			c2.8-1,5.4-1.9,7.9-2.9c2-0.8,3.8-1,5.8,0.1c2.7,1.4,5.8,1.5,8.5,0.4c2-0.8,3.7-0.9,5.7-0.3c2.1,0.6,4.1,0.1,5.5-1.5
			C665.5,402.5,665,400.6,664.1,399z M556.5,459.7c-0.1-0.2-0.2-0.4-0.3-0.6c2.2-1.7,4.9-2.1,7.5-2.9c0.1,0.2,0.1,0.3,0.2,0.5
			C561.4,457.6,559,458.7,556.5,459.7z M568.8,445.8c-9,3.3-17.9,6.6-26.9,9.8c-4.4,1.5-5.4,0.7-4.9-4c0.3-3.5,1.7-6.7,4.3-9
			c1.9-1.7,4.3-3,6.7-4c2.2-0.9,4.5-0.6,6.3,1.1c3.3,3.2,7.2,4.8,11.7,4.9c1.2,0,2.3,0.3,3.9,0.4C569.3,445.6,569,445.7,568.8,445.8
			z M570.9,450.6c-0.1-0.1-0.2-0.3-0.2-0.4c1.4-1.1,2.7-2.3,4.5-2.7c0.1,0.2,0.2,0.4,0.4,0.6C574,448.9,572.5,449.7,570.9,450.6z
			 M543.9,388.8c-3.5-3.7-5.5-8.1-6.1-13.1c-0.2-1.7-0.7-3.3-1.2-5c-1-3.6-1-7.2-0.2-10.8c0.2-0.7,1.5-1.5,2.4-1.7
			c2.7-0.6,5.4-0.8,8.2-1.2v1.4c1-0.4,1.9-0.8,2.8-1c1.3-0.3,2.5-0.6,3.9,0.4c1,0.7,2.9,0.3,4.3,0.3c1.3,0,2.6,0,4.1,0
			c-0.2,0.7-0.4,1.1-0.7,2c6.4-2.2,12.4-4.2,18.2-6.2C576.3,361.9,551.5,386.4,543.9,388.8z"/>
	</defs>
	<clipPath id="SVGID_4_">
		<use xlink:href="#SVGID_3_"  style="overflow:visible;"/>
	</clipPath>
	<g class="st11">
		<path class="st12 ld17" d="M613.6,316.2c-12,9.6-55.7,25.7-72.4,25.7"/>
		<path class="st3 ld18" d="M532,361.7c0,28.1,9.3,35.2,28.6,56.2"/>
		<path class="st13 ld19" d="M609.1,351.7c-19.3,27.5-87.4,36.7-108.4,55"/>
		<path class="st14 ld20" d="M506.6,419.7c22.7-21.2,114.5-69.7,149.9-23"/>
		<path class="st14 ld21" d="M650.1,389.8c-3.9,9.7-41.5,9.4-41.5,9.4"/>
		<path class="st6 ld22" d="M582,403.3c0.2,13.5,12.2,34.9-21.1,25.9c0,0-43.5-11.9-37.3,35.5"/>
		<path class="st1 ld23" d="M523.6,464.7c0,0,68.2-8.3,92-45.7"/>
		<path class="st15 ld24" d="M623.8,408.3c13.4,10.2,15.7,56.4,5.6,63c-6.6-6.7-18.7-12-18.7-12"/>
	</g>
</g>
<g id="_x33_">
	<defs>
		<path id="SVGID_5_" d="M737,549.7c-1,0.1-2-0.1-2.7-0.5c1.1-0.4,2.6-0.5,2.2-1.9c0.4-0.5-0.1-1.3-0.7-1l0.3-0.3
			c-0.9-1-2.3-0.6-3.5-0.9c-4.9,0-10.1-1.1-14.7-0.5c-1.4-2-1.4,1.7-2.9-0.2c-0.4,0.2-0.6,0.9-1.2,1.3c-0.7,0.1-1.5-0.1-1.7-0.5
			c0-0.3,0.4-0.3,0.7-0.3l-0.3-0.5c0.6,0,0.6-0.5,0.9-0.9l0.3,0.3l0.2-0.2c-0.2-0.1-0.4-0.3-0.4-0.6l-0.4,0.5
			c-0.7-0.7,0.5-1.4-0.9-1.7l0.4-0.3c-2.7-0.5-5.1-0.2-7.1-2.4c-1.7,0.1-3.4-0.8-4.7-1c0.1-0.1,0.1-0.2,0.3-0.1l-0.3-0.8
			c0.3-0.3-0.1-0.6,0.1-0.8c-0.5-1.5-2.7-0.5-4-1.1c-5.1-4.4-12.2-5.3-17.8-9c-1.8-0.8-3.7-2.2-5.4-1c-0.9-0.3-2.5,0.1-2.7-0.9
			c-2-0.3-4-0.5-5.9-1.1c-3.7-3.3-6.8-7.4-11.4-10.1l-3.6,0.3c-3.2-0.7-6.4,1.3-8.9-0.5c-0.4,0.5-1,0.7-1.7,0.6
			c-0.3-0.1-1.2-0.4-1-1.1c-10.5,1.6-19.2-3.4-28.7-5.1c-7-7.6-15-15.5-24-21.1l-5-0.5c-1.1-0.2-2.3,0.3-3.3-0.1
			c-2.3,0.4-3.9-1.5-6.2-1.5c-1.3,0.2-2.3,0.7-3,1.7c-2.2,0.6-1.9,2.9-2.6,4.7c0.4,0.9-1.6,0.9-0.5,1.4c-0.9,1.1-2.2,1.7-3.5,1.4
			c-2.4,2.4-5.9,2.7-9,2.9c-2.2,1-5.4,1.1-6.4,3.4l-0.1-0.2c-2.9,2.7-6.4,6.3-10.6,7.8c-0.2-0.1-0.1-0.3-0.1-0.5
			c-3-0.7-4.9,2.4-7.3,3.6c-0.6-0.5-1.4,1-2.1,0c0.1-0.1,0-0.3,0.2-0.5c-2.2,0.5-3.8,2.5-5.4,4c-3.5,1.3-4.3,6.9-9,5.5
			c-0.2,0.2-0.5,0.3-0.4,0.6c-1.7-0.2-3.9,0.4-5.7,0.7c-1.1,1.1-2.4,1.3-3.8,1.9c0.4,1.2-1.4,0.5-2.1,0.8c-1.2,1.1-3.3,1.6-4.7,2.1
			c-0.1,0.3-0.6,0.6-0.1,1c-0.2,0.8-1.2,1.4-2.1,1.1c-2.6,2.8-7.7,4.1-8.8,8c-2.4,1.3-2.2,4.1-4.6,4.8l0.5,0.5l-0.7,0.4
			c-0.9,1,0.9,2,0.5,3h-0.5l1.5,0.8c0.1,1.7,2.7,3,2.1,4.8c1.1,2.4,2.7,4.8,3.3,7.2c1.6-0.2,1.8,2.2,3.5,1.4l0.1,0.5
			c0.4,0.5-0.4,1.9,0.5,2.4c0.6,0.1,1-0.1,1.2-0.5c2.2-0.1,2.7,1.5,4.8,1.1c-0.6,0.6-1.2,1.2-1.7,1.6l0.4,0.5c0.5-0.6,1.5,0,1.9-0.9
			h-0.3c-0.4-0.2-0.4-0.5-0.4-0.8c0.7-0.2,1.4-1.7,2.2-0.7c0.1-0.2,0.1-0.5,0.4-0.6c1-0.5,0.8,0.9,1.7,0.6c0.1-0.1,0.4-0.3,0.4-0.5
			c-0.4-0.5,0.7-1.5,1-2.3c0.4-0.7,1.2-0.1,1.6,0.2l0.5-0.7c1.1,0.3,2.1,0.1,3.1,0.2c0.8,0.3,0.3,0.7,0.2,1.3c0.4,0.6,1.2,1,2,0.6
			c1.3,0.4,0.4,1.2,1.4,1.8l2.2-1c1.2-5.8,8.2-6.6,12.3-9.3c2.8,0.2,5.4-2.2,8.3-2.2c0-0.2,0.1-0.4-0.1-0.5c0.1-0.5,0.9-0.9,1.4-0.3
			c1-0.5,2.9,0.4,2.8-0.8c0.2-0.1,0.3,0.2,0.5,0.3c0.6-0.4,1.2-0.4,1.4-1.1l1.2,0.5c0.9-1.5,1.6-2.5,2.2-4c0.6-0.5,0.9-2.2,1.9-2.6
			c0.2-0.4,1.8-1.5,0.9-2.4c0.2-0.7,1.2-0.5,1.6-1.1l-0.2-0.2c1.5-1.3,2.3-4.1,4.6-4.8c0.3-0.5,1.4-0.1,1.1-0.9l-0.5,0.5
			c-0.6,0.4-1.1-0.1-1.6-0.1c-0.2,1.1-1.5,1.5-2.2,1.8c0.9-1.1,2.1-2.1,2.6-3.2c4.3-3.7,8.7-6.7,14.2-7.2c-0.2-0.2-0.5-0.3-0.4-0.7
			L564,523c-0.6-0.5-2.1-0.1-2.4-1.3c0.9-0.5,1.7-0.7,2.1-1.7c2.4-1,5.3-1.8,7.9-2.6c1.2-0.3,2-0.1,2.9-1.1l-0.9-0.5
			c0.5-1.8,1.2-3.8,2.8-5.2c1.4-0.7,2.8,1.3,3.8,0.5c2.6,0.8,4.6,1.5,7.1,2.5c5.6,4.9,12,8.7,16.5,14.8c1,1.5,2.4,2.8,3.7,4
			c1.3,1.6,2.8,3.6,4.3,5c1.2,0.7,2.2,1.9,3.8,1.9l4.3,4.5c3.5-0.1,7.6,2,10.9,2c0.6,1,1.7,0.1,2.4,0.7l-0.2,0.1
			c1.2,0.3,2.7,1.3,4.1,0.9v0.3c0.2,0,0.4,0,0.6,0.2v-0.4c2.1,0.8,4.8,1.9,7.2,1.4c1.5,0.1,3.8,0.1,4.9-0.6c3,0.9,6.2-0.9,9.3,0.1
			c0.4,1.9,1.9,2.9,2.8,4.5c4.4,0.8,4.1,5.4,7.5,7.6c4-0.9,7.9,0.2,12.1,0.3c0.1-0.7,1-0.7,1.4-0.7c-0.3-0.7-1.6-0.5-1.6-1.4
			l-0.5,0.3c0.2-0.4-0.6-0.9,0.2-1.3c1.1-0.3,2,0.5,2.6,0.7l-0.2-0.5c0.3-0.5,0.8-0.5,1.2-0.8c0.6,1.2,2.7,0.8,3.3,1.8
			c2.8,0.3,5.3,1.9,8.1,1.9c-0.3-0.4-0.7-0.6-1.2-0.8c0.3-0.3,0.1-0.8,0.1-1.1c1.1-1.1,1.7,0.7,2.7,0.5l-0.1-0.6
			c1.5-0.7,2.8,0.6,4,1.3c-0.6-0.9-1.8-1.5-1.4-2.8c0.5-0.3,1.2-0.7,1.7-0.3l-0.7-0.5c0.3-0.2,0.3-0.5,0.4-0.7
			c-0.4,0.3-0.7,0-1.2-0.1c-0.6-1.6,1-0.3,1.1-1.6l0.4,0.3c1.2,0.9,1.6-0.8,2.6-1.3c2.6,0.2,0.4-1.8,1.7-2.7
			c0.3-0.4,1.2-1.3,1.9-0.5c0.7-0.9,1.7,0.4,2.5-0.5l-0.4-0.7c0.5-0.5,1-0.9,1.7-0.9c0.7,0.9,3.2,0.8,2.3,1.9c0.8,0,0.9,0.6,1,1.1
			c1.5,0.5,3.3,1.1,5.1,1.3c0.5,1.7,3.3,0.9,4.9,1.1c0.7-1.5,2-0.9,3.5-1.1c-0.6-0.7,0.4-0.7,0.5-1.3l1.1,0.8
			c0.6,0.2,0.8-0.9,1.4-0.3c0.2-0.1,0.3-0.3,0.5-0.3V551l2.6-0.2C735.6,550.5,737.3,551.1,737,549.7z M711.6,542.6l0.3-0.3l0.7,0.6
			L711.6,542.6z"/>
	</defs>
	<clipPath id="SVGID_6_">
		<use xlink:href="#SVGID_5_"  style="overflow:visible;"/>
	</clipPath>
	<path class="op8 ld25" d="M503,546c100-92,141,15,221.5,15.5"/>
</g>
<path id="_x34_" class="st17 op" d="M493.4,96.2c-0.5,1.4-1.1,1.6-2.2,1.6h-16.6v5h7.9v4.4h-7.2v30.9h2.8l0,3.9l0,0
	c-0.2,0.6-0.7,0.9-1.3,0.9h-2.5v4.8h5.8l0.1,0c1.6,0,2.9,0,2.9-2l0-7.6h3.9v7.6c0.1,1.9,1.3,2,2.9,2l0.1,0h6V143h-2.5
	c-0.6,0-1-0.3-1.3-0.9l0,0l0-3.9l0.2,0c2.8-0.1,4-1.4,4-4v-26.8h-8v-4.4h5.6c2,0,3.2-0.4,4.1-1.5c1-1.2,1.6-2.1,1.8-5.2H493.4z
	 M491.3,112.1v4.2H484l0,4.6h7.3v3.8H484v4.6h7.2v1.8c0,1.2-0.7,1.9-1.7,1.9h-9.3v-21H491.3z M468.2,102.8h1.7v-4.9h-18v45.6
	c0,2.9,1.4,4.3,4.1,4.3h13.9v-5.1h-1.7v-4.1l0.2,0c2.2-0.1,3.2-1.3,3.2-4v-27.3h-3.4V102.8z M463.2,107.3h-3.4v31.4h3.4v4h-4.8
	c-0.6,0-1.7-0.6-1.7-1.8v-38.1h6.5V107.3z M467.4,132c0,1.3-0.4,2-1.2,2H464v-22.2h3.4V132z M494.4,174.7v-18.1h-40.9v23.3h35.6
	C492.7,179.9,494.4,178.2,494.4,174.7z M488.3,165.9h-23.8v5h23.8v0.9c0,1.5-0.6,3.3-1.8,3.3h-27.1v-13.4h28.9V165.9z M451.9,183.5
	v22.9h38.9c3.6,0,5.3-1.7,5.3-5.2v-17.7H451.9z M490.2,192.7h-6.4v5h6.4v0.9c0,1.4-0.6,2.9-1.8,2.9H458v-12.9h32.3V192.7z
	 M480,192.7h-17.3v5H480V192.7z"/>
<path id="_x35_" class="st18 op" d="M732.9,361.3c-0.6,0.4-1.6,1.1-2.8,2.1l-4.2,3.2l-4,3.1c1.4,0.1,3.1,0.1,5.3,0.1
	c1.6,0,2.7,0,3.3-0.1c0.6-0.1,1-0.3,1.4-0.7c0.4-0.4,0.6-0.9,0.6-1.5c0-0.3,0.1-0.4,0.2-0.4c0.1,0,0.1,0,0.2,0.1
	c0,0.1,0.1,0.5,0.1,1.4c0,0.9,0.1,1.6,0.1,2.2l0,1.4v0.2c0,0.2-0.1,0.3-0.2,0.3c-0.1,0-0.2-0.1-0.2-0.3c-0.1-0.7-0.3-1.2-0.6-1.5
	c-0.3-0.3-0.8-0.5-1.3-0.5c-0.6-0.1-2.6-0.1-5.9-0.1l-6-0.1c-0.3,0-0.5-0.1-0.5-0.2c0,0,0-0.1,0-0.1c0-0.1,0.4-0.4,1.1-0.9
	c0.7-0.5,1.6-1.2,2.6-2c1-0.8,1.8-1.4,2.5-1.9c1.4-1,2.2-1.6,2.5-1.9c1.2-1,1.9-1.6,2.2-1.7c0.2-0.1,0.3-0.2,0.3-0.3
	c0-0.1-0.1-0.1-0.4-0.1l-1.7,0c-2.2,0-4,0.1-5.3,0.2c-1.3,0.1-2.1,0.2-2.3,0.3c-0.2,0.1-0.5,0.3-0.7,0.6c-0.2,0.3-0.3,0.6-0.3,0.9
	c0,0.4-0.1,0.7-0.3,0.7c-0.1,0-0.2-0.1-0.2-0.4l0-4.9c0-0.2,0.1-0.3,0.2-0.3c0.1,0,0.2,0.1,0.2,0.3c0,0.8,0.2,1.4,0.6,1.7
	c0.4,0.3,1.3,0.4,2.6,0.4l4-0.1c2.3,0,3.7-0.1,4.1-0.2c0.4-0.1,0.9-0.4,1.5-0.8c0.6-0.4,0.9-0.9,1-1.4c0-0.2,0.1-0.3,0.2-0.3
	c0.1,0,0.2,0.1,0.2,0.4L732.9,361.3z M719.7,378.6c-0.5-0.3-0.9-0.7-1.2-1.2c-0.3-0.5-0.4-1-0.4-1.6c0-0.7,0.2-1.2,0.6-1.6
	c0.4-0.4,0.9-0.6,1.6-0.6c0.5,0,1,0.1,1.4,0.4c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.8,0.7,1.6c0.4,1.1,0.7,1.7,1,1.9
	c0.3,0.2,0.6,0.3,1,0.3c0.7,0,1.2-0.1,1.6-0.4c0.4-0.3,0.6-0.7,0.6-1.2c0-0.4-0.1-0.7-0.3-1c-0.2-0.2-0.6-0.4-1.3-0.5
	c-0.7-0.1-1-0.4-1-0.8c0-0.1,0.1-0.3,0.2-0.5c0.2-0.2,0.4-0.3,0.7-0.3c0.6,0,1.1,0.3,1.5,0.8c0.4,0.6,0.6,1.3,0.6,2.3
	c0,0.8-0.1,1.4-0.4,1.9c-0.2,0.5-0.5,0.8-0.8,0.9c-0.3,0.1-0.9,0.2-1.8,0.2l-3-0.1c-1.2,0-2,0.1-2.5,0.2c-0.5,0.1-0.7,0.4-0.7,0.7
	c0,0.1,0,0.2,0,0.3l0,0.2c0,0.2-0.1,0.2-0.2,0.2c-0.3,0-0.4-0.7-0.4-2c0-0.4,0.1-0.6,0.3-0.8C718.7,378.7,719.1,378.6,719.7,378.6z
	 M724.1,378.6c-0.3-0.2-0.6-0.7-1.1-1.4c-0.4-0.7-0.8-1.2-1.2-1.5c-0.4-0.3-0.8-0.5-1.4-0.5c-0.5,0-0.9,0.1-1.2,0.4
	c-0.3,0.3-0.5,0.6-0.5,1c0,0.4,0.1,0.8,0.4,1.1s0.6,0.6,1,0.8c0.4,0.2,1,0.2,1.8,0.2C722.7,378.7,723.4,378.7,724.1,378.6z
	 M726.9,384.8c-1.7-0.1-3.3-0.1-4.9-0.1c-1.2,0-2,0.1-2.4,0.4c-0.4,0.3-0.6,0.6-0.6,1.1c0,0.4,0.1,0.8,0.4,1.2
	c0.1,0.2,0.2,0.3,0.2,0.3c0,0.1-0.1,0.1-0.2,0.1c-0.2,0-0.5-0.2-0.9-0.7c-0.4-0.5-0.6-1.1-0.6-1.8c0-0.7,0.3-1.2,0.8-1.6
	c0.5-0.3,1.3-0.5,2.4-0.5c1.1,0,3,0.1,5.7,0.2c0,0,0-0.4,0.1-1l0-0.6c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.3
	c0.1,0.3,0.4,0.7,1,1.4c0.5,0.7,1.3,1,2.3,1.1c0.3,0,0.4,0.1,0.4,0.2c0,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0c-0.3,0-0.6,0-1,0
	l-1.8,0l0,2.2c0,0.4,0,0.6-0.1,0.6c-0.3,0-0.6-0.1-0.7-0.2C727.1,387.1,727,386.2,726.9,384.8z M727.7,390.7c-0.5,0-1.9,0.1-4.2,0.1
	c-1.5,0-2.4,0-2.9,0.1c-0.5,0.1-0.9,0.2-1.2,0.5c-0.3,0.3-0.5,0.6-0.5,1c0,0.5,0.3,1,0.8,1.5c0.5,0.5,1,0.8,1.6,0.9
	c0.6,0.1,1.9,0.2,4,0.2c1,0,1.5-0.1,1.7-0.2c0.2-0.1,0.3-0.4,0.3-0.8c0-0.2,0.1-0.2,0.2-0.2c0.1,0,0.1,0,0.2,0.1c0,0,0,0.2,0,0.5v2
	c-0.8,0.1-1.7,0.1-2.8,0.1l-4.2-0.1c-0.9,0-1.4,0.1-1.6,0.2c-0.2,0.1-0.4,0.3-0.4,0.6c0,0.2-0.1,0.3-0.2,0.3c-0.1,0-0.2-0.2-0.2-0.5
	v-1.6c0-0.3,0.1-0.4,0.3-0.4c0.1,0,0.1,0,0.2,0c0.2,0,0.5,0,0.7,0c0.2,0,0.5,0,0.9,0c-0.7-0.5-1.3-1-1.7-1.6
	c-0.4-0.6-0.6-1.1-0.6-1.7c0-0.5,0.1-0.9,0.4-1.3c0.3-0.3,0.6-0.6,1-0.7c0.4-0.1,1.4-0.2,2.9-0.2c1.6,0,2.8-0.1,3.7-0.1
	c0.5,0,0.8,0,0.9-0.1c0.1,0,0.2-0.2,0.2-0.4c0-0.3,0.1-0.4,0.2-0.4c0.1,0,0.2,0.2,0.2,0.5V390.7z M725.4,401.3
	c0.8,0.4,1.5,0.9,1.9,1.4c0.4,0.5,0.6,1,0.6,1.5c0,0.4-0.1,0.7-0.4,1c-0.3,0.3-0.6,0.4-1,0.4c-0.7,0-1.1-0.3-1.1-0.8
	c0-0.3,0.2-0.5,0.6-0.6c0.5-0.2,0.8-0.5,0.8-0.9c0-0.3-0.2-0.7-0.6-1c-0.4-0.4-0.9-0.6-1.4-0.7c-0.5-0.1-1.5-0.2-3.1-0.2
	c-1.5,0-2.3,0.1-2.5,0.2c-0.2,0.1-0.4,0.4-0.4,0.8c0,0.1-0.1,0.2-0.2,0.2c-0.2,0-0.2-0.2-0.2-0.5v-3.2c0-0.2,0.1-0.3,0.2-0.3
	c0.1,0,0.2,0.2,0.2,0.5c0,0.4,0.2,0.7,0.4,0.8c0.3,0.1,1.2,0.2,2.9,0.2c2.5,0,3.9,0,4.4-0.1c0.5-0.1,0.8-0.4,0.8-1
	c0-0.2,0.1-0.4,0.2-0.4c0.1,0,0.1,0,0.2,0.1c0,0,0.1,0.4,0.1,0.9l0.1,1.9C727.4,401.2,726.6,401.3,725.4,401.3z M719.7,411.7
	c-0.5-0.3-0.9-0.7-1.2-1.2c-0.3-0.5-0.4-1-0.4-1.6c0-0.7,0.2-1.2,0.6-1.6c0.4-0.4,0.9-0.6,1.6-0.6c0.5,0,1,0.1,1.4,0.4
	c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.8,0.7,1.6c0.4,1.1,0.7,1.7,1,1.9c0.3,0.2,0.6,0.3,1,0.3c0.7,0,1.2-0.1,1.6-0.4
	c0.4-0.3,0.6-0.7,0.6-1.2c0-0.4-0.1-0.7-0.3-1c-0.2-0.2-0.6-0.4-1.3-0.5c-0.7-0.1-1-0.4-1-0.8c0-0.1,0.1-0.3,0.2-0.5
	c0.2-0.2,0.4-0.3,0.7-0.3c0.6,0,1.1,0.3,1.5,0.8c0.4,0.6,0.6,1.3,0.6,2.3c0,0.8-0.1,1.4-0.4,1.9c-0.2,0.5-0.5,0.8-0.8,0.9
	c-0.3,0.1-0.9,0.2-1.8,0.2l-3-0.1c-1.2,0-2,0.1-2.5,0.2c-0.5,0.1-0.7,0.4-0.7,0.7c0,0.1,0,0.2,0,0.3l0,0.2c0,0.2-0.1,0.2-0.2,0.2
	c-0.3,0-0.4-0.7-0.4-2c0-0.4,0.1-0.6,0.3-0.8C718.7,411.8,719.1,411.8,719.7,411.7z M724.1,411.7c-0.3-0.2-0.6-0.7-1.1-1.4
	c-0.4-0.7-0.8-1.2-1.2-1.5c-0.4-0.3-0.8-0.5-1.4-0.5c-0.5,0-0.9,0.1-1.2,0.4c-0.3,0.3-0.5,0.6-0.5,1c0,0.4,0.1,0.8,0.4,1.1
	c0.3,0.3,0.6,0.6,1,0.8c0.4,0.2,1,0.2,1.8,0.2C722.7,411.8,723.4,411.8,724.1,411.7z M733.7,418.1c-0.9,0-1.9,0-2.8,0l-7,0l-3.4,0
	c-0.6,0-1.1,0.1-1.4,0.2c-0.3,0.2-0.5,0.5-0.5,1.1c0,0.2-0.1,0.3-0.2,0.3c-0.1,0-0.2-0.1-0.2-0.3c0,0,0-0.1,0-0.2
	c0.1-0.7,0.1-1.5,0.1-2.5c0-0.6,0-1.1-0.1-1.4c0-0.1,0-0.1,0-0.2c0-0.2,0.1-0.3,0.2-0.3c0.1,0,0.2,0.1,0.2,0.3c0,0.6,0.2,1,0.6,1.2
	c0.4,0.2,1,0.3,1.9,0.3l6.4,0c2.1,0,3.3,0,3.7-0.1c0.4,0,0.7-0.2,1-0.4c0.3-0.2,0.4-0.6,0.5-1c0-0.3,0.1-0.4,0.2-0.4
	c0.1,0,0.2,0.1,0.2,0.3c0.1,1.2,0.3,2.2,0.5,2.8V418.1z M726.2,432.9c-0.4,1.3-0.9,2.2-1.5,2.8c-0.7,0.6-1.5,0.9-2.4,0.9
	c-1,0-2-0.4-2.8-1.2c-0.9-0.8-1.3-1.9-1.3-3.4c0-0.3,0-0.7,0.1-1.4c0-0.4,0-0.6,0-0.6c0-2.1,0-3.5-0.1-4.2c0-0.2,0.1-0.4,0.2-0.4
	c0.1,0,0.1,0,0.2,0.1c0,0.1,0.1,0.3,0.2,0.8c0.1,0.3,0.2,0.5,0.4,0.6c0.2,0.2,0.6,0.3,1.2,0.4c0.6,0.1,1.6,0.1,2.8,0.1
	c1.2,0,2.2,0,2.9,0c0.7,0,1.3,0,1.7,0c1.2,0,2.2,0,3-0.1c0.8-0.1,1.2-0.2,1.4-0.4c0.2-0.2,0.4-0.5,0.4-1c0-0.2,0.1-0.3,0.2-0.3
	c0.1,0,0.2,0.1,0.2,0.3l0,1.8c0,0.6,0,1.2,0,1.7c0.1,1.2,0.1,1.9,0.1,2.3c0,1.3-0.3,2.2-0.9,3c-0.6,0.7-1.4,1.1-2.5,1.1
	c-0.8,0-1.5-0.2-2.1-0.7C727.1,434.8,726.6,434,726.2,432.9z M725.7,429.4c-0.2-0.1-0.5-0.1-1-0.1l-3.5-0.1c-1.2,0-1.9,0-2.1,0.1
	c-0.1,0.1-0.3,0.3-0.4,0.6c-0.1,0.3-0.1,0.9-0.1,1.6c0,0.6,0.1,1.2,0.3,1.6c0.2,0.4,0.6,0.8,1.2,1.1c0.6,0.3,1.3,0.5,2.1,0.5
	c1.2,0,2.1-0.3,2.7-1c0.6-0.6,0.9-1.6,0.9-2.9C725.8,430.4,725.8,429.9,725.7,429.4z M732.4,429.4c-1.6-0.1-3-0.1-4-0.1l-1.7,0
	c-0.2,0-0.4,0-0.4,0.1c-0.1,0.1-0.1,0.2-0.1,0.5c0,1.3,0.3,2.3,0.8,3.1c0.5,0.8,1.4,1.2,2.6,1.2c1,0,1.8-0.3,2.3-0.9
	c0.5-0.6,0.8-1.3,0.8-2.2C732.8,430.6,732.7,430,732.4,429.4z M719.7,443c-0.5-0.3-0.9-0.7-1.2-1.2c-0.3-0.5-0.4-1-0.4-1.6
	c0-0.7,0.2-1.2,0.6-1.6c0.4-0.4,0.9-0.6,1.6-0.6c0.5,0,1,0.1,1.4,0.4c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.8,0.7,1.6
	c0.4,1.1,0.7,1.7,1,1.9c0.3,0.2,0.6,0.3,1,0.3c0.7,0,1.2-0.1,1.6-0.4c0.4-0.3,0.6-0.7,0.6-1.2c0-0.4-0.1-0.7-0.3-1
	c-0.2-0.2-0.6-0.4-1.3-0.5c-0.7-0.1-1-0.4-1-0.8c0-0.1,0.1-0.3,0.2-0.5c0.2-0.2,0.4-0.3,0.7-0.3c0.6,0,1.1,0.3,1.5,0.8
	c0.4,0.6,0.6,1.3,0.6,2.3c0,0.8-0.1,1.4-0.4,1.9c-0.2,0.5-0.5,0.8-0.8,0.9c-0.3,0.1-0.9,0.2-1.8,0.2l-3-0.1c-1.2,0-2,0.1-2.5,0.2
	c-0.5,0.1-0.7,0.4-0.7,0.7c0,0.1,0,0.2,0,0.3l0,0.2c0,0.2-0.1,0.2-0.2,0.2c-0.3,0-0.4-0.7-0.4-2c0-0.4,0.1-0.6,0.3-0.8
	C718.7,443.1,719.1,443,719.7,443z M724.1,443c-0.3-0.2-0.6-0.7-1.1-1.4c-0.4-0.7-0.8-1.2-1.2-1.5c-0.4-0.3-0.8-0.5-1.4-0.5
	c-0.5,0-0.9,0.1-1.2,0.4c-0.3,0.3-0.5,0.6-0.5,1c0,0.4,0.1,0.8,0.4,1.1c0.3,0.3,0.6,0.6,1,0.8c0.4,0.2,1,0.2,1.8,0.2
	C722.7,443.1,723.4,443,724.1,443z M727.9,449c-0.3,0-0.6,0-0.9,0c-0.2,0-0.6,0-1,0c0.7,0.7,1.2,1.2,1.4,1.5
	c0.2,0.4,0.3,0.8,0.3,1.2c0,1-0.5,1.9-1.4,2.7c-1,0.7-2.1,1.1-3.4,1.1c-1.4,0-2.5-0.4-3.4-1.3c-0.9-0.8-1.3-1.8-1.3-2.9
	c0-0.8,0.3-1.5,0.8-2.3c-0.9-0.1-1.9-0.2-3.1-0.2c-0.7,0-1.2,0-1.5,0.1l-0.4,0c-0.5,0.1-0.8,0.5-0.9,1c0,0.2-0.1,0.3-0.3,0.3l-0.1,0
	l0-0.1c0.1-0.8,0.1-1.5,0.1-2c0-1.2,0-1.9,0-2.2c0-0.2,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2c0.1,0.6,0.2,1,0.5,1.2
	c0.2,0.2,1.3,0.3,3.2,0.3c1.9,0,3.1,0.1,3.5,0.1l3.1,0l1.8,0c0.9,0,1.6-0.1,1.8-0.2c0.3-0.1,0.4-0.4,0.5-0.9c0-0.2,0.1-0.2,0.2-0.2
	c0.1,0,0.1,0,0.2,0.1c0,0.1,0.1,0.4,0.1,0.9L727.9,449z M722.9,448.9c-1.4,0-2.3,0-2.6,0.1c-0.4,0.1-0.7,0.3-1.1,0.7
	c-0.3,0.4-0.5,0.9-0.5,1.5c0,0.8,0.4,1.5,1.2,2c0.8,0.5,1.7,0.8,2.9,0.8c1.3,0,2.3-0.3,3.1-0.8c0.8-0.5,1.2-1.1,1.2-1.9
	c0-0.7-0.3-1.3-1-1.7S724.3,448.9,722.9,448.9z M726.9,459.6c-1.7-0.1-3.3-0.1-4.9-0.1c-1.2,0-2,0.1-2.4,0.4
	c-0.4,0.3-0.6,0.6-0.6,1.1c0,0.4,0.1,0.8,0.4,1.2c0.1,0.2,0.2,0.3,0.2,0.3c0,0.1-0.1,0.1-0.2,0.1c-0.2,0-0.5-0.2-0.9-0.7
	c-0.4-0.5-0.6-1.1-0.6-1.8c0-0.7,0.3-1.2,0.8-1.6c0.5-0.3,1.3-0.5,2.4-0.5c1.1,0,3,0.1,5.7,0.2c0,0,0-0.4,0.1-1l0-0.6
	c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.3c0.1,0.3,0.4,0.7,1,1.4c0.5,0.7,1.3,1,2.3,1.1c0.3,0,0.4,0.1,0.4,0.2
	c0,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0c-0.3,0-0.6,0-1,0l-1.8,0l0,2.2c0,0.4,0,0.6-0.1,0.6c-0.3,0-0.6-0.1-0.7-0.2
	C727.1,461.9,727,461,726.9,459.6z M727.9,466l-2.5,0l-2.4,0h-1.8l-1.4,0c-0.5,0-0.9,0.1-1,0.2c-0.1,0.2-0.2,0.4-0.2,0.7
	c0,0.4-0.1,0.6-0.3,0.6c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0-0.1,0-0.2c0.1-0.6,0.1-1.2,0.1-1.8c0-0.9,0-1.5-0.1-1.8c0-0.1,0-0.1,0-0.2
	c0-0.2,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.3,0.6c0.1,0.4,0.1,0.6,0.3,0.7c0.1,0.1,0.5,0.2,1.1,0.2l4.7,0.1c1.2,0,2-0.1,2.2-0.3
	c0.2-0.2,0.4-0.5,0.4-1c0-0.2,0.1-0.2,0.2-0.2c0.1,0,0.1,0,0.2,0.1c0,0.1,0.1,0.5,0.1,1.2C727.9,465.2,727.9,465.7,727.9,466z
	 M733.5,464.9c0,0.3-0.1,0.5-0.3,0.7c-0.2,0.2-0.4,0.3-0.7,0.3c-0.3,0-0.5-0.1-0.8-0.2c-0.2-0.2-0.3-0.4-0.3-0.7
	c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.4-0.3,0.7-0.3c0.3,0,0.6,0.1,0.8,0.3C733.4,464.4,733.5,464.6,733.5,464.9z M721.2,474.7
	c-0.9,0-1.6-0.3-2.2-0.9c-0.6-0.6-0.9-1.3-0.9-2.1c0-0.5,0.2-1,0.5-1.7c0.2-0.4,0.3-0.6,0.3-0.6c0-0.1-0.1-0.2-0.2-0.3
	c-0.2-0.1-0.3-0.2-0.3-0.3c0-0.1,0.1-0.1,0.3-0.2c0.2,0,0.7,0,1.5,0c0.7,0,1.1,0,1.3,0c0.3,0,0.4,0.1,0.4,0.2c0,0.1-0.1,0.1-0.2,0.2
	c-0.9,0.3-1.6,0.6-2.2,1.1c-0.6,0.5-0.9,1-0.9,1.7c0,0.5,0.2,0.9,0.5,1.2c0.3,0.4,0.7,0.5,1.3,0.5c0.8,0,1.6-0.6,2.2-1.9
	c0.4-0.9,0.8-1.5,1-1.8c0.2-0.3,0.5-0.5,0.8-0.7c0.3-0.2,0.7-0.3,1.1-0.3c0.8,0,1.4,0.3,1.9,0.8c0.5,0.5,0.8,1.2,0.8,1.9
	c0,0.6-0.2,1.2-0.6,1.8c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.2,0.1,0.2,0.2c0,0.1,0,0.1-0.1,0.1
	c-0.1,0-0.4,0.1-1,0.1c-0.6,0-1.1,0.1-1.5,0.1c-0.2,0-0.4-0.1-0.4-0.2c0-0.1,0.1-0.2,0.3-0.2c0.2-0.1,0.6-0.2,1.2-0.5
	c0.6-0.3,0.9-0.6,1.1-0.9c0.2-0.3,0.2-0.6,0.2-0.9c0-0.5-0.1-0.9-0.4-1.2c-0.3-0.3-0.6-0.5-1-0.5c-0.3,0-0.5,0.1-0.7,0.2
	c-0.2,0.1-0.4,0.2-0.5,0.4c-0.1,0.1-0.5,0.8-1,1.9c-0.4,0.7-0.7,1.2-0.8,1.4c-0.2,0.2-0.5,0.4-0.8,0.6
	C722,474.6,721.6,474.7,721.2,474.7z M728,478.7c-0.3,0.1-0.6,0.1-0.8,0.1c-0.4,0-0.7,0-1.1,0c0.6,0.5,1.1,1,1.4,1.5
	c0.3,0.5,0.5,1,0.5,1.6c0,0.5-0.1,0.9-0.4,1.3c-0.3,0.4-0.6,0.8-1.2,1.1c0.5,0.5,0.9,1,1.2,1.5c0.3,0.5,0.4,1,0.4,1.5
	c0,0.7-0.2,1.2-0.5,1.6c-0.3,0.4-0.6,0.7-1,0.8c-0.4,0.1-1,0.2-2,0.2l-4.2,0c-0.6,0-1,0.1-1.3,0.2c-0.2,0.1-0.3,0.3-0.3,0.7
	c0,0.2-0.1,0.3-0.2,0.3c-0.1,0-0.2-0.2-0.2-0.6v-3.1c0-0.3,0.1-0.5,0.2-0.5c0.1,0,0.2,0.1,0.2,0.3c0,0.6,0.2,0.9,0.4,1
	c0.3,0.1,1.6,0.2,4.1,0.2c1.2,0,2,0,2.5-0.1c0.5-0.1,0.9-0.3,1.2-0.6c0.3-0.3,0.5-0.6,0.5-1.1c0-0.4-0.1-0.7-0.4-1.1
	c-0.3-0.4-0.6-0.7-1.1-1c-1-0.3-2.7-0.4-5.2-0.4c-0.9,0-1.4,0.1-1.7,0.2c-0.2,0.1-0.3,0.4-0.3,0.8v0.2c0,0.2-0.1,0.3-0.2,0.3
	c-0.1,0-0.2-0.1-0.2-0.4v-3.4c0-0.3,0.1-0.5,0.2-0.5c0.1,0,0.2,0.1,0.2,0.3c0,0.4,0.1,0.7,0.3,0.8c0.2,0.1,0.7,0.2,1.6,0.3
	c0.9,0,1.8,0.1,2.7,0.1c1.6,0,2.7-0.1,3.2-0.4c0.5-0.3,0.8-0.7,0.8-1.2c0-0.5-0.2-0.9-0.6-1.4c-0.4-0.5-1-0.8-1.6-0.9
	c-0.7-0.2-1.9-0.2-3.7-0.2c-1.2,0-2,0.1-2.3,0.2c-0.3,0.1-0.4,0.4-0.4,0.8c0,0.2,0,0.3,0,0.3c0,0-0.1,0.1-0.2,0.1
	c-0.1,0-0.2-0.1-0.2-0.4v-3.1c0-0.4,0.1-0.6,0.2-0.6c0.1,0,0.2,0.1,0.2,0.2c0,0.4,0.1,0.6,0.2,0.7c0.1,0.1,0.5,0.2,1.2,0.3
	c0.7,0.1,1.8,0.1,3.3,0.1c1.5,0,2.5,0,2.8,0c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.9c0-0.2,0.1-0.3,0.2-0.3
	c0.1,0,0.1,0,0.2,0.1c0,0,0.1,0.4,0.1,0.9c0.1,0.6,0.1,1.1,0.1,1.5C728,478.3,728,478.5,728,478.7z M696.6,369.5c0-0.3,0-0.5,0-0.6
	c0-0.3,0-0.7,0-1.4c0-0.7,0-2,0-4.1v-2.6c0-0.1,0-0.4-0.1-1c0-0.6-0.1-1-0.1-1.1c0-0.5,0.1-0.8,0.2-0.8c0.1,0,0.2,0.1,0.2,0.3
	c0,0.7,0.2,1.1,0.5,1.4c0.3,0.3,0.6,0.4,1.1,0.5c0.5,0.1,1.9,0.1,4.1,0.1l4,0c1.3,0,2.2-0.1,2.7-0.2c0.5-0.1,0.9-0.3,1.2-0.6
	c0.3-0.3,0.4-0.7,0.5-1.1c0-0.2,0.1-0.3,0.2-0.3c0.1,0,0.2,0.1,0.2,0.3c0,0,0,0.1,0,0.3c-0.1,1.1-0.1,2.3-0.1,3.8l0,1.8
	c0,0.2-0.1,0.3-0.2,0.3c-0.1,0-0.2-0.1-0.2-0.3c0-0.5-0.1-0.9-0.3-1.2c-0.2-0.3-0.5-0.5-0.7-0.6c-0.3-0.1-0.8-0.2-1.6-0.2l-5.3-0.1
	l-3,0c-0.9,0-1.5,0-1.8,0.1c-0.3,0.1-0.5,0.2-0.7,0.4c-0.2,0.2-0.3,0.6-0.3,1.2c0,0,0,0.7,0.1,1.8c0.1,0.9,0.6,1.8,1.5,2.5
	c0.9,0.7,1.6,1.2,2.3,1.5c0.2,0.1,0.3,0.2,0.3,0.3c0,0.1-0.1,0.2-0.3,0.2c-0.2,0-1-0.1-2.3-0.3C697.6,369.6,696.8,369.5,696.6,369.5
	z M706.4,373.5l-2.5,0l-2.4,0h-1.8l-1.4,0c-0.5,0-0.9,0.1-1,0.2c-0.1,0.2-0.2,0.4-0.2,0.7c0,0.4-0.1,0.6-0.3,0.6
	c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0-0.1,0-0.2c0.1-0.6,0.1-1.2,0.1-1.8c0-0.9,0-1.5-0.1-1.8c0-0.1,0-0.1,0-0.2c0-0.2,0.1-0.2,0.2-0.2
	c0.1,0,0.2,0.2,0.3,0.6c0.1,0.4,0.1,0.6,0.3,0.7c0.1,0.1,0.5,0.2,1.1,0.2l4.7,0.1c1.2,0,2-0.1,2.2-0.3c0.2-0.2,0.4-0.5,0.4-1
	c0-0.2,0.1-0.2,0.2-0.2c0.1,0,0.1,0,0.2,0.1c0,0.1,0.1,0.5,0.1,1.2C706.3,372.6,706.4,373.2,706.4,373.5z M711.9,372.3
	c0,0.3-0.1,0.5-0.3,0.7c-0.2,0.2-0.4,0.3-0.7,0.3c-0.3,0-0.5-0.1-0.8-0.2c-0.2-0.2-0.3-0.4-0.3-0.7c0-0.3,0.1-0.5,0.3-0.7
	c0.2-0.2,0.4-0.3,0.7-0.3c0.3,0,0.6,0.1,0.8,0.3C711.8,371.8,711.9,372.1,711.9,372.3z M698.7,380.7c3.6,1.5,5.8,2.2,6.5,2.2
	c0.3,0,0.5-0.2,0.6-0.6c0-0.2,0.1-0.3,0.2-0.3c0.1,0,0.2,0.1,0.2,0.3l0,1.5l0,0.9c0,0.2-0.1,0.3-0.2,0.3c-0.1,0-0.2-0.1-0.2-0.2
	c-0.1-0.6-0.3-1-0.8-1.2c-0.5-0.3-1.3-0.6-2.5-1c-2-0.7-3.4-1.2-4.2-1.6c-0.7-0.3-1.2-0.5-1.4-0.6c-0.3-0.1-0.4-0.2-0.4-0.3
	c0-0.1,0.2-0.2,0.5-0.3l2.9-1.2l3.1-1.3c1.1-0.4,1.8-0.7,2.1-0.9c0.3-0.2,0.5-0.3,0.6-0.4c0.1-0.1,0.1-0.3,0.2-0.5
	c0-0.2,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.3l0,1.8c0,0.8,0,1.3,0,1.4c0,0.2-0.1,0.4-0.2,0.4c-0.1,0-0.2-0.1-0.2-0.4
	c-0.1-0.3-0.3-0.5-0.6-0.5C704.6,378.2,702.4,379,698.7,380.7z M706.4,388.4l-2.5,0l-2.4,0h-1.8l-1.4,0c-0.5,0-0.9,0.1-1,0.2
	c-0.1,0.2-0.2,0.4-0.2,0.7c0,0.4-0.1,0.6-0.3,0.6c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0-0.1,0-0.2c0.1-0.6,0.1-1.2,0.1-1.8
	c0-0.9,0-1.5-0.1-1.8c0-0.1,0-0.1,0-0.2c0-0.2,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.3,0.6c0.1,0.4,0.1,0.6,0.3,0.7
	c0.1,0.1,0.5,0.2,1.1,0.2l4.7,0.1c1.2,0,2-0.1,2.2-0.3c0.2-0.2,0.4-0.5,0.4-1c0-0.2,0.1-0.2,0.2-0.2c0.1,0,0.1,0,0.2,0.1
	c0,0.1,0.1,0.5,0.1,1.2C706.3,387.6,706.4,388.1,706.4,388.4z M711.9,387.3c0,0.3-0.1,0.5-0.3,0.7c-0.2,0.2-0.4,0.3-0.7,0.3
	c-0.3,0-0.5-0.1-0.8-0.2c-0.2-0.2-0.3-0.4-0.3-0.7c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.4-0.3,0.7-0.3c0.3,0,0.6,0.1,0.8,0.3
	C711.8,386.8,711.9,387,711.9,387.3z M706.3,393.1c-0.4,0.2-1.2,0.3-2.2,0.3c0.7,0.4,1.3,0.9,1.7,1.4c0.4,0.5,0.6,1.1,0.6,1.7
	c0,0.5-0.1,0.9-0.3,1.3c-0.2,0.4-0.5,0.8-1,1c-0.5,0.2-1.3,0.4-2.4,0.4l-3.8-0.1c-0.7,0-1.1,0.1-1.3,0.2c-0.2,0.1-0.3,0.3-0.4,0.7
	c-0.1,0.2-0.2,0.4-0.2,0.4c-0.1,0-0.2-0.1-0.2-0.2c0,0,0-0.1,0-0.1c0.1-0.3,0.1-0.8,0.1-1.4c0-0.5,0-1.1-0.1-1.8
	c0-0.2,0.1-0.4,0.2-0.4c0.1,0,0.1,0.1,0.2,0.2c0.1,0.4,0.2,0.6,0.3,0.7c0.1,0.1,0.2,0.2,0.4,0.2c0.2,0,0.7,0.1,1.6,0.1
	c0.9,0,1.7,0,2.3,0c1.6,0,2.6-0.2,3.2-0.5c0.5-0.4,0.8-0.8,0.8-1.3c0-0.8-0.5-1.4-1.4-2c-1-0.5-2.5-0.8-4.8-0.8
	c-1,0-1.6,0.1-1.9,0.2c-0.3,0.1-0.4,0.4-0.5,0.9c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2l0-0.5c0-0.2,0-0.8,0-1.9l0-0.8
	c0-0.3,0.1-0.4,0.2-0.4c0.1,0,0.2,0.2,0.3,0.5c0.1,0.3,0.2,0.5,0.4,0.6c0.2,0.1,0.7,0.2,1.7,0.2c1,0,2.1,0.1,3.6,0.1
	c1.3,0,2.2-0.1,2.5-0.2c0.3-0.2,0.5-0.4,0.6-0.7c0.1-0.3,0.2-0.4,0.3-0.4c0.1,0,0.2,0.1,0.2,0.3l0,0.8
	C706.3,391.8,706.3,392.3,706.3,393.1z M704.8,408.5c-0.5,0.3-1.1,0.5-1.8,0.5c-1,0-1.8-0.3-2.5-0.9c-0.7-0.6-1.1-1.4-1.2-2.5
	c-0.1-0.7-0.1-1.2-0.2-1.4c-0.1-0.3-0.2-0.5-0.4-0.7c-0.2-0.2-0.4-0.3-0.6-0.3c-0.3,0-0.5,0.2-0.7,0.5c-0.2,0.3-0.2,1-0.3,2
	c-0.1,1.5-0.1,2.5-0.2,3c-0.1,0.5-0.3,0.9-0.8,1.3c-0.4,0.4-1,0.5-1.7,0.5c-1.1,0-2.1-0.5-2.8-1.4c-0.7-0.9-1.1-2.2-1.1-4
	c0-1.3,0.2-2.3,0.6-3c0.4-0.7,0.9-1,1.7-1c1.2,0,2.1,1,2.9,3c0.1-0.8,0.4-1.4,0.6-1.8c0.3-0.3,0.6-0.5,1.1-0.5
	c0.5,0,0.9,0.2,1.3,0.5c0.4,0.4,0.7,0.9,0.9,1.6c0.2-0.6,0.6-1.2,1.1-1.6c0.5-0.4,1.1-0.6,1.9-0.6c1,0,1.9,0.4,2.7,1.1
	c0.8,0.7,1.2,1.6,1.2,2.7c0,1.1-0.4,1.9-1.2,2.6c0.9,0.7,1.3,1.3,1.3,2c0,0.4-0.1,0.8-0.4,1c-0.3,0.2-0.6,0.4-0.9,0.4
	c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.3,0.2-0.6,0.7-0.8c0.3-0.1,0.4-0.3,0.4-0.4
	C705.6,409.4,705.3,409,704.8,408.5z M695.6,406.3c0-0.7-0.1-1.4-0.4-2c-0.3-0.6-0.6-1-0.9-1.3c-0.4-0.3-0.8-0.4-1.3-0.4
	c-0.7,0-1.2,0.3-1.5,0.9c-0.3,0.6-0.5,1.3-0.5,2.2c0,1.3,0.3,2.3,0.9,2.9c0.6,0.6,1.2,1,1.9,1c0.6,0,1.1-0.3,1.4-0.8
	C695.5,408.1,695.6,407.3,695.6,406.3z M702.9,403.6c-1.1,0-1.9,0.2-2.4,0.7c-0.5,0.4-0.7,0.9-0.7,1.4c0,0.5,0.2,0.9,0.7,1.2
	c0.5,0.4,1.2,0.5,2.2,0.5c1,0,1.8-0.2,2.4-0.5c0.6-0.4,0.8-0.8,0.8-1.3c0-0.6-0.3-1-0.8-1.4C704.6,403.8,703.8,403.6,702.9,403.6z
	 M704.2,420.7l-2.8,0c-1.9,0-3,0-3.3,0.1c-0.2,0.1-0.5,0.2-0.7,0.3c-0.2,0.2-0.4,0.6-0.4,1.1c0,0.3-0.1,0.5-0.2,0.5
	c-0.2,0-0.2-0.1-0.2-0.3c0-0.1,0-0.3,0.1-0.6c0.1-0.7,0.1-1.3,0.1-1.9c0-0.5,0-1.1-0.1-1.9l0-0.6c0-0.2,0.1-0.3,0.2-0.3
	c0.2,0,0.2,0.2,0.2,0.5c0,0.4,0.1,0.7,0.4,0.9c0.3,0.2,1,0.3,2.1,0.4c1.1,0.1,2.6,0.1,4.4,0.1c1.7,0,3.1,0,4.1-0.1
	c1-0.1,1.6-0.1,1.9-0.2c0.3-0.1,0.5-0.2,0.7-0.4c0.1-0.2,0.2-0.6,0.3-1.1c0-0.2,0.1-0.3,0.2-0.3c0.1,0,0.2,0.1,0.2,0.3
	c0.2,1.7,0.2,4.9,0.2,9.6c0,0.8,0,1.2,0,1.3c0,0.1-0.1,0.1-0.1,0.2c-0.1,0-0.6,0.1-1.6,0.3c-1,0.2-1.5,0.2-1.5,0.2
	c-0.2,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.3c1.8-1,2.8-2.5,2.8-4.6c0-0.7,0-1.3-0.1-1.8c-0.1-0.5-0.2-0.7-0.3-0.8
	c-0.1-0.1-0.6-0.1-1.4-0.1c-0.8,0-2.3,0-4.4,0c0,0.5,0,0.9,0,1.1c0,1,0.2,1.7,0.7,2c0.5,0.4,0.9,0.6,1.4,0.6c0.3,0,0.5,0.1,0.5,0.2
	c0,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0-0.3,0c-0.4-0.1-1.5-0.1-3.3-0.1H702c-0.3,0-0.5-0.1-0.5-0.3c0-0.1,0.1-0.2,0.3-0.2
	c0.7,0,1.2-0.2,1.7-0.6c0.4-0.4,0.6-1.1,0.6-2.2C704.2,421.4,704.2,421.1,704.2,420.7z M701.9,429.8c-0.4,0-0.7,0-0.9,0
	c-1.1,0-2,0.3-2.7,0.8c-0.7,0.6-1.1,1.3-1.1,2.1c0,0.8,0.4,1.6,1.1,2.3c0.2,0.2,0.3,0.3,0.3,0.4c0,0.1-0.1,0.2-0.3,0.2
	c-0.5-0.3-1-0.7-1.3-1.3c-0.4-0.6-0.5-1.3-0.5-2.1c0-1.1,0.4-2.1,1.3-2.9c0.9-0.8,2-1.2,3.4-1.2c1.5,0,2.7,0.4,3.7,1.3
	c1,0.9,1.5,1.8,1.5,2.7c0,0.9-0.4,1.7-1.2,2.5c-0.8,0.7-1.7,1.1-2.7,1.1c-0.2,0-0.3,0-0.4-0.1c-0.1,0-0.1-0.3-0.1-0.8L701.9,429.8z
	 M702.5,429.8c0,0.9-0.1,1.7-0.1,2.6l0,0.5c0,0.4,0.1,0.7,0.3,0.9c0.2,0.2,0.4,0.3,0.9,0.3c0.6,0,1.2-0.2,1.7-0.6
	c0.5-0.4,0.7-0.9,0.7-1.4c0-0.5-0.3-1-0.8-1.5C704.6,430.2,703.7,429.9,702.5,429.8z M698.1,441.7c-0.5-0.3-0.9-0.7-1.2-1.2
	c-0.3-0.5-0.4-1-0.4-1.6c0-0.7,0.2-1.2,0.6-1.6c0.4-0.4,0.9-0.6,1.6-0.6c0.5,0,1,0.1,1.4,0.4c0.4,0.2,0.7,0.5,0.9,0.8
	c0.2,0.3,0.4,0.8,0.7,1.6c0.4,1.1,0.7,1.7,1,1.9c0.3,0.2,0.6,0.3,1,0.3c0.7,0,1.2-0.1,1.6-0.4c0.4-0.3,0.6-0.7,0.6-1.2
	c0-0.4-0.1-0.7-0.3-1c-0.2-0.2-0.6-0.4-1.3-0.5c-0.7-0.1-1-0.4-1-0.8c0-0.1,0.1-0.3,0.2-0.5c0.2-0.2,0.4-0.3,0.7-0.3
	c0.6,0,1.1,0.3,1.5,0.8c0.4,0.6,0.6,1.3,0.6,2.3c0,0.8-0.1,1.4-0.4,1.9c-0.2,0.5-0.5,0.8-0.8,0.9c-0.3,0.1-0.9,0.2-1.8,0.2l-3-0.1
	c-1.2,0-2,0.1-2.5,0.2c-0.5,0.1-0.7,0.4-0.7,0.7c0,0.1,0,0.2,0,0.3l0,0.2c0,0.2-0.1,0.2-0.2,0.2c-0.3,0-0.4-0.7-0.4-2
	c0-0.4,0.1-0.6,0.3-0.8C697.1,441.8,697.5,441.8,698.1,441.7z M702.5,441.7c-0.3-0.2-0.6-0.7-1.1-1.4c-0.4-0.7-0.8-1.2-1.2-1.5
	c-0.4-0.3-0.8-0.5-1.4-0.5c-0.5,0-0.9,0.1-1.2,0.4s-0.5,0.6-0.5,1c0,0.4,0.1,0.8,0.4,1.1c0.3,0.3,0.6,0.6,1,0.8
	c0.4,0.2,1,0.2,1.8,0.2C701.1,441.8,701.8,441.8,702.5,441.7z M699.7,451.7c-0.9,0-1.6-0.3-2.2-0.9c-0.6-0.6-0.9-1.3-0.9-2.1
	c0-0.5,0.2-1,0.5-1.7c0.2-0.4,0.3-0.6,0.3-0.6c0-0.1-0.1-0.2-0.2-0.3c-0.2-0.1-0.3-0.2-0.3-0.3c0-0.1,0.1-0.1,0.3-0.2
	c0.2,0,0.7,0,1.5,0c0.7,0,1.1,0,1.3,0c0.3,0,0.4,0.1,0.4,0.2c0,0.1-0.1,0.1-0.2,0.2c-0.9,0.3-1.6,0.6-2.2,1.1
	c-0.6,0.5-0.9,1-0.9,1.7c0,0.5,0.2,0.9,0.5,1.2c0.3,0.4,0.7,0.5,1.3,0.5c0.8,0,1.6-0.6,2.2-1.9c0.4-0.9,0.8-1.5,1-1.8
	c0.2-0.3,0.5-0.5,0.8-0.7c0.3-0.2,0.7-0.3,1.1-0.3c0.8,0,1.4,0.3,1.9,0.8c0.5,0.5,0.8,1.2,0.8,1.9c0,0.6-0.2,1.2-0.6,1.8
	c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.2,0.1,0.2,0.2c0,0.1,0,0.1-0.1,0.1c-0.1,0-0.4,0.1-1,0.1
	c-0.6,0-1.1,0.1-1.5,0.1c-0.2,0-0.4-0.1-0.4-0.2c0-0.1,0.1-0.2,0.3-0.2c0.2-0.1,0.6-0.2,1.2-0.5c0.6-0.3,0.9-0.6,1.1-0.9
	c0.2-0.3,0.2-0.6,0.2-0.9c0-0.5-0.1-0.9-0.4-1.2c-0.3-0.3-0.6-0.5-1-0.5c-0.3,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.2-0.5,0.4
	c-0.1,0.1-0.5,0.8-1,1.9c-0.4,0.7-0.7,1.2-0.8,1.4c-0.2,0.2-0.5,0.4-0.8,0.6C700.4,451.7,700,451.7,699.7,451.7z M705.3,456.2
	c-1.7-0.1-3.3-0.1-4.9-0.1c-1.2,0-2,0.1-2.4,0.4c-0.4,0.3-0.6,0.6-0.6,1.1c0,0.4,0.1,0.8,0.4,1.2c0.1,0.2,0.2,0.3,0.2,0.3
	c0,0.1-0.1,0.1-0.2,0.1c-0.2,0-0.5-0.2-0.9-0.7c-0.4-0.5-0.6-1.1-0.6-1.8c0-0.7,0.3-1.2,0.8-1.6c0.5-0.3,1.3-0.5,2.4-0.5
	c1.1,0,3,0.1,5.7,0.2c0,0,0-0.4,0.1-1l0-0.6c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.3c0.1,0.3,0.4,0.7,1,1.4
	c0.5,0.7,1.3,1,2.3,1.1c0.3,0,0.4,0.1,0.4,0.2s-0.1,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0c-0.3,0-0.6,0-1,0l-1.8,0l0,2.2
	c0,0.4,0,0.6-0.1,0.6c-0.3,0-0.6-0.1-0.7-0.2C705.5,458.5,705.4,457.6,705.3,456.2z"/>
<path id="_x36_" class="st19 op" d="M687.2,479.8v1.9h4.9l0,3.1h-4.7c-0.1,0-0.2,0.1-0.2,0.3v18.2c0,0.2,0.1,0.3,0.2,0.3h2v2.4h-6.9
	v-2.8h2.6c0.2,0,0.3-0.1,0.3-0.3v-17.8c0-0.1-0.1-0.2-0.3-0.3h-2.6v-3.2h2.9v-1.9h-10.6c-0.4,0-0.6,0.2-0.6,0.7v26.7
	c0,0.5,0.2,0.7,0.6,0.7h14.6h1.6h0.2v-4.3h3.5v4.3h1.4h0.4h4h0.6h1.2v-26.2v-0.6v-1.3H687.2z M683.4,486.8c0.1,0,0.1,0,0.1,0.1v14.4
	c0,0.1-0.1,0.1-0.1,0.2h-3.8c0,0,0,0-0.1-0.1v-14.4c0,0,0,0,0.1-0.1H683.4z M680.7,506h-4.5c-0.2,0-0.3-0.1-0.3-0.3V482
	c0-0.2,0.1-0.3,0.3-0.3h4.5v3.2H678c-0.1,0-0.2,0.1-0.2,0.3V503c0,0.2,0.1,0.3,0.2,0.3h2.6V506z M688.9,486.8
	C688.9,486.7,688.9,486.7,688.9,486.8l8.1-0.1c0.1,0,0.1,0,0.1,0.1v10.5h-5.1v-1.9h3.8l0-5.9h-7V486.8z M689,501.7
	C688.9,501.7,688.9,501.7,689,501.7l-0.1-10.3h5.1v1.9h-3.8l0,5.9l7,0v2.3c0,0.1,0,0.1-0.1,0.2H689z M700.5,506h-4v-2.4h2.2
	c0.2,0,0.3-0.1,0.3-0.3v-18.2c0-0.1-0.1-0.2-0.3-0.3H694l0-3.1h6.5V506z"/>
</svg>
        </div>
        <img src="./s1/text1.png" alt="雙語校園｜無瑕景觀｜ 豐居久安" class="img bottom_img text_img"/>
        <img src="./s1/title.png" alt="早鳥豐收價 24.8萬起" class="img titleimg" />
      </div>
      <div v-if="isMobile">
        <img src="./s1/mo/bg.jpg" alt class="img" />
        <img src="./s1/mo/bg2.jpg" alt class="img fix mix" />
        <img src="./s1/mo/bg3.png" alt class="img bottom_img"/>
        <img src="./s1/mo/img1.png" alt class="img fix" />
        <!-- <img src="./s1/mo/logo.png" alt class="img" /> -->
        <div class="logo-icon">
<svg version="1.1" id="圖層_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 750 1271" style="enable-background:new 0 0 750 1271;" xml:space="preserve">
<pattern  x="187.5" y="302" width="165.3" height="231.9" patternUnits="userSpaceOnUse" id="img_3" viewBox="70.3 -233.8 165.3 231.9" style="overflow:visible;">
	<g>
		<rect x="70.3" y="-233.8" class="st0" width="165.3" height="231.9"/>
		
			<image style="overflow:visible;" width="500" height="580" xlink:href="./s1/logobg1.jpg"  transform="matrix(0.3306 0 0 0.3998 165.3 -231.8918)">
		</image>
		
			<image style="overflow:visible;" width="500" height="580" xlink:href="./s1/logobg1.jpg"  transform="matrix(0.3306 0 0 0.3998 0 -231.8918)">
		</image>
		
			<image style="overflow:visible;" width="500" height="580" xlink:href="./s1/logobg1.jpg"  transform="matrix(0.3306 0 0 0.3998 165.3 -463.7837)">
		</image>
		
			<image style="overflow:visible;" width="500" height="580" xlink:href="./s1/logobg1.jpg"  transform="matrix(0.3306 0 0 0.3998 0 -463.7837)">
		</image>
	</g>
</pattern>
<pattern  x="187.5" y="302" width="170" height="184.6" patternUnits="userSpaceOnUse" id="img_2" viewBox="69.7 -367.9 170 184.6" style="overflow:visible;">
	<g>
		<rect x="69.7" y="-367.9" class="st0" width="170" height="184.6"/>
		
			<image style="overflow:visible;" width="500" height="543" xlink:href="./s1/logobg2.jpg"  transform="matrix(0.34 0 0 0.34 170 -184.62)">
		</image>
		
			<image style="overflow:visible;" width="500" height="543" xlink:href="./s1/logobg2.jpg"  transform="matrix(0.34 0 0 0.34 0 -184.62)">
		</image>
		
			<image style="overflow:visible;" width="500" height="543" xlink:href="./s1/logobg2.jpg"  transform="matrix(0.34 0 0 0.34 170 -369.24)">
		</image>
		
			<image style="overflow:visible;" width="500" height="543" xlink:href="./s1/logobg2.jpg"  transform="matrix(0.34 0 0 0.34 0 -369.24)">
		</image>
	</g>
</pattern>
<g>
	<defs>
		<path id="SVGID_1_" d="M447.4,203.9c-0.7-1.7-1.9-3.4-3.3-4.6c-4.7-3.9-10.3-5.4-16.1-6.4c-0.7-0.1-2,0.5-2.3,1.1
			c-0.6,1.2-1.3,1.3-2.4,1.3c-6.1,0-12.3,0-18.4,0c-2.2,0-4.3-0.1-6,1.5c-0.2,0.2-0.7,0.3-1,0.3c-3.5,0-7,0-10.5,0
			c5.9-1.3,11.9-2.2,17.7-4c5.5-1.8,10.7-3.3,16.7-2.7c3.5,0.4,6.9-1.4,8-5.3c1.3-4.4,2.5-8.7,3.5-13.1c0.6-2.9,0.9-5.9,1.2-8.8
			c0.2-2,0.2-4.1,0.2-6.2c0-1.7-0.2-3.4-0.2-5.1c0-2.5,0.5-5,0.3-7.4c-0.4-6-1.1-12-1.7-17.9c-0.2-2.4-0.3-4.9-1.9-6.9
			c-3-3.9-8.6-5.3-13.4-3.8c-2,0.6-2.3,1.7-2.2,3.4c0.1,1.5,0,3,0,4.8c-1.1-0.7-2-1-2.5-1.6c-1.8-2.3-4.5-3.1-7.1-3.9
			c-1-0.3-2-0.8-2.9-1.3c-0.5-0.3-1.2-0.8-1.4-1.3c-1.7-4.1-5.1-6.5-8.5-8.9c-1.6-1.1-2.3-0.7-2.5,1.2c-0.2,2.2,0,4.5-0.2,6.7
			c-0.4,5-0.8,5.6-4.7,6.9c-1,0.3-1.8,0.8-2.8,1.2c-1.9,0.8-3.1,0.6-5.4,1.5c-0.3-9.5-3.8-14.7-4.3-22.5c-0.2-2.6-2.1-4.2-4.6-4.8
			c-2.3-0.5-3.8,1.1-5,2.8c-1.9,2.7-2,5.9-2.8,8.9c-1.4,5.6-2.4,11.1-2.3,16.9c0.1,10.8,0,21.7,0,32.5c0,0.9-0.2,1.8-0.2,2.9
			c-0.5-0.2-0.6-0.3-0.8-0.4c-4.4-3.6-9-6.6-14.7-7.4c-0.6-0.1-1.6-1.1-1.6-1.8c-0.1-1.4,0.3-2.9,0.6-4.4c0-0.1,0.4-0.2,0.6-0.2
			c2-0.4,3.9-0.8,5.9-1.1c3-0.5,4.8-2.3,6.3-4.8c1.2-2-0.8-2.6-1.3-3.8c-0.6-1.3-1.8-2.5-3-3.2c-2.3-1.3-4.8-2.2-7.2-3.1
			c-1.2-0.5-1.9-1-2.4-2.3c-1.5-3.3-3.4-6.5-5.2-9.7c-0.4-0.7-1.1-1.1-2-1.8c-0.3,1.1-0.6,1.7-0.8,2.4c-0.6,3-1.3,5.9-1.7,8.9
			c-0.4,2.6-1.7,4.3-3.9,5.4c-2.5,1.3-5,2.6-7.5,3.9c-1.8,0.9-2.4,2.5-1.3,3.8c1.3,1.5,2.8,2.8,5,2.9c0.9,0,1.8,0.6,2.7,1
			c-0.4,0.8-0.6,1.8-1.1,2.5c-2.5,3.6-5.2,6.9-7.6,10.6c-2.3,3.6-1.3,5.7,2.9,6.6c0.7,0.1,1.5,0.3,2,0.7c1.7,1.5,3.6,1.3,5.6,1.1
			c0.6-0.1,1.1-0.2,1.6-0.3c0.1,0.1,0.2,0.3,0.3,0.4c-1,1.4-2,2.7-3.1,4.1c-1.1,1.5-2.4,3-3.4,4.7c-1.2,2.2-0.8,3.4,1.3,4.6
			c3.3,2,6.8,3.3,10.8,3.4c3,0,4.4,1.3,5.1,4c-6.1,1.2-12.2,1.2-18.9,0.4c1.8-2.2,1-3.9,0.2-5.6c-0.6-1.1-1.2-2.3-1.8-3.4
			c-2.4-4.2-4.1-8.6-4.8-13.4c-0.4-2.9-0.7-5.8-1.4-8.6c-2.7-10.5-2.1-20.9,0.6-31.3c0.3-1.3,0.4-2.6,0.4-4c-0.1-1.4-1-2.5-2.4-2.5
			c-0.8,0-1.7,0.8-2.4,1.4c-0.6,0.6-0.8,1.5-1.2,2.2c-4.1,9.2-5.8,18.6-4.4,28.7c1.3,9.6,2.7,19.1,7.3,27.6
			c2.3,4.3,2.9,8.1,0.7,12.4c-0.2,0.4-0.3,1.1,0,1.5c1.1,1.7,2.4,3.1,4.4,3.4c3.1,0.5,6.1,1,9.2,1.2c4.9,0.3,9.8,0.3,14.7,0.4
			c9.5,0.1,18.9,0.1,28.4,0.1c0.7,0,1.4,0.1,2.1,0.1c-0.8,0.5-1.6,0.5-2.3,0.6c-20.5,2.8-40.9,6.8-60.9,12.2
			c-7.7,2.1-15.3,4.6-23,6.6c-1.8,0.5-3,1.1-3.7,2.8c-0.5,1.1-0.3,1.7,0.8,2.1c2.6,1,5.2,1.8,8,1.1c5.7-1.5,11.5-3.2,17.2-4.7
			c12.8-3.4,25.5-7.6,38.7-9.2c4.2-0.5,8.1-2.6,12.3-3.7c10.8-2.7,20.2-3.8,31.3-5.1c2.4-0.3-12.8,5.1-23.5,8.8
			c-8.3,2.9-16.5,6.3-24.7,9.4c-1.4,0.5-2.9,0.9-4.4,1.3c-1.5,0.4-3.2,0.4-4.5,1.2c-1.2,0.8-2,2.4-3,3.7c-0.1,0.1-0.2,0.3-0.2,0.5
			c-0.3,5.2,0.3,10,4.7,13.5c3,2.4,6.1,4.9,10.4,5c-0.7,0.9-1.4,1.5-2.1,2.2c-1.1,1-2.6,1.8-3.2,3.1c-1,2.1-2.5,3.4-4.4,4.6
			c-4.4,2.8-4.6,6-0.9,9.8c0.7,0.7,1.4,1.6,2.2,2.5c-2.6,1.6-5.2,1.8-7.8,2.1c-3.7,0.4-7.4,0.8-11.1,1.6c-2.7,0.5-5.2,1.6-7.8,2.4
			c-2.9,0.9-5.8,1.6-8.7,2.6c-0.7,0.2-1.7,1.5-1.5,1.8c0.9,1.9,1.3,4.4,3.5,5.5c4.2,2,8.6,3.2,13.2,2.2c3-0.7,5.8-2.2,8.8-2.9
			c9.9-2.5,19.9-5,29.8-7.3c1.9-0.5,4-1,5.8-0.5c1.7,0.4,3,0,4.4-0.4c0.9-0.3,1.7-0.8,2.6-1.2c3.1-1.3,6.2-2.2,10.1-1.8
			c-0.9,0.5-1.3,0.7-1.8,1c-0.4,0.3-0.7,0.6-1.1,0.8c5.8-2,11.8-2.1,17.7-2.3c5.9-0.2,11.5,1,17.1,2.7c0,0.2,0,0.3-0.1,0.5
			c-6.6,2.7-13.2,5.4-19.8,8c0,0.2,0.1,0.4,0.1,0.6c1.5,0,3,0,4.9,0c-1.4,0.7-2.6,1.1-3.5,1.9c-1.2,1-2.2-0.2-3.2,0.1
			c-3,0.9-6,1.9-9,2.8c0.7,0.5,1.3,0.8,2,0.8c2.2,0,4.3-0.1,6.5-0.1c6.3,0,12.5,0.7,18.7-1c2.8-0.8,5.8-1.4,8.7-1.6
			c4.7-0.3,9.5-0.1,14.3-0.5c2.7-0.2,5.4-1.1,7.9-2.1c2-0.8,2.4-2.5,1.3-4.3c-0.8-1.4-1.9-2.7-2.9-3.9c-4.5-4.9-10.2-7.9-16.4-9.6
			c-6.4-1.8-13-2.8-19.5-4.1c-0.6-0.1-1.1-0.4-1.4-0.5c2.6-1.9,5.2-3.6,7.6-5.6c3-2.5,3.4-5.5,0.9-8.6c-4.1-5.2-9.2-8.6-16-8.8
			c-0.9,0-1.8-0.3-2.6-0.4c0-0.2,0-0.4-0.1-0.5c0.5-0.4,0.9-0.9,1.4-1.2c1.7-1.2,3.4-2.4,5.2-3.6c2.6-1.8,5.1-3.7,7.9-5.4
			c2.8-1.7,3.3-3.2,1.5-6c-0.6-0.9-1.3-1.8-2.1-2.4c-2.3-1.8-4.6-3.5-6.9-5.1c-4.9-3.4-10-3.3-15.2-0.5c-4.2,2.2-8.4,4.3-12.6,6.5
			c-0.8,0.4-1.4,0.9-2.6,1.7c0.9,0.7,1.4,1.2,2,1.7c0.6,0.5,1.3,1,2.2,1.6c-6.3,5.4-12.3,10.7-18.3,16c-1.1-2.2-2-4-2.8-5.8
			c-1.3-2.6-1-3.8,1.3-5.4c1.4-1,3.1-1.7,4.5-2.7c1.2-0.9,2.3-2,3.3-3.1c1.7-1.7,3.3-3.5,5-5.1c0.4-0.4,1-0.5,1.6-0.7
			c5.5-1.8,11-3.6,16.5-5.5c11-3.7,21.9-7.2,33.8-5.3c5.3,0.8,10.8,0.6,16.2,0.9c3.8,0.2,7.6,0.5,11.2,2.1c0.9,0.4,1.8,0.5,2.8,0.6
			C446.5,209.8,448.8,207,447.4,203.9z M403.4,137.3c0.2-1.9,1.2-3,3-3.3c2.3-0.4,4.7-0.7,7.6-1.1c-0.4,5.1-0.8,9.6-1.3,14.6
			c-0.9-0.6-1.5-1-2.1-1.4c-1.8-1.2-3.4-2.6-5.3-3.7C403.4,141.1,403.2,139.3,403.4,137.3z M402.3,158.2c2.3-0.7,4.5-1.3,6.6-2
			c1.7-0.6,2.6,0.3,2.6,1.7c0,2.4-0.3,4.8-0.7,7.1c-0.2,1.1-1.2,1.4-2.3,1c-1.7-0.7-3.5-1.2-5.1-2c-0.6-0.3-1.3-1.3-1.4-2
			C401.9,160.8,402.2,159.5,402.3,158.2z M397.4,178.8c0.3-1.1,0.8-2.2,1-3.3c0.2-1.3,0.5-2.8,1.9-2.9c1.4-0.1,2.8,0.3,4.2,0.7
			c0.4,0.1,0.7,0.6,1.1,0.8c0.5,0.4,1.1,0.7,1.6,1.1c-0.5,0.5-0.8,1.2-1.4,1.5c-2.9,1.7-5.8,3.2-8.7,4.8c-0.1-0.1-0.2-0.2-0.4-0.4
			C397,180.4,397.2,179.6,397.4,178.8z M358.9,185.3c0,1.6-0.5,2.9-2.4,2.8c-3.6-0.1-7.2-0.1-10.8-0.2c-0.4,0-0.7,0-1.4,0
			c0.2-0.6,2.6-1.5,2.5-2.1c-0.2-1.2-0.4-2.4-0.8-3.5c-0.8-2.1,0.4-2,1.9-2.9c0.2-0.1,1.9-0.7,2.4-0.8c0.6-0.2,4.8-1.4,5.2-1.5
			c0.4-4.6-4.1-6.2-5.9-6.3c-1.4,0.2-3.5-0.2-5.2,0.2c-1.4,0.4-1.9,0-2-1.4c-0.1-0.8-0.1-0.6,0-1.4c0.1-1.9,0.7-2.1,2.3-1.2
			c0.3,0.2,0.7,0.4,1,0.3c3.8-1.5,7.6,0,11.5-0.2c0.4,0,1.1,0.9,1.2,1.5c0.4,1.5,0.6,3.1,0.7,4.7C359,177.4,359,181.4,358.9,185.3z
			 M379.7,129.1c2.9-0.3,5.8-0.2,8.7,0c0.4,0,1.3,1.6,1.1,2.2c-0.5,1.4-1.3,7.4-2.4,8.4c-2.8,2.6-4.1,2.6-8.6,2.9
			c-0.2-0.1-0.3-0.2-0.5-0.3c0-2.6-0.1-4.3-0.1-6.9c0-0.9,0-2.1,0-3.4C377.7,129.4,378.4,129.2,379.7,129.1z M382.9,176.4
			c-0.6,2.2-0.7,6.9-1.2,7.8c-0.8,1.3-4,2.1-5.8,1.4c-1.9-0.7-1.7-2.3-1.4-3.8c0.6-2.9,1.1-6.4,1.8-9.3c0.3-1.3,1-1.7,2.2-1.2
			c0.8,0.3,1.8,1.1,2.6,1.4C383.2,173.6,383.5,174.1,382.9,176.4z M386.9,157.2c-0.9,1.2-1.2,4.8-2.3,5.7c-2.2,1.9-4.9,0.4-7.5,1.3
			c0.5-4.2,0.6-5.8,1-9.4c0.2-1.6,1-2,2.5-1.5c1.8,0.6,3.7,1.1,5.6,1.7C387.2,155.3,387.6,156.2,386.9,157.2z M379.1,248
			c-5.4,4.4-11.6,7.4-17.8,10.4C366.4,253.4,371.8,249,379.1,248z"/>
	</defs>
	<clipPath id="SVGID_2_">
		<use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
	</clipPath>
	<g class="st0">
		<path class="st1 ld1" d="M368,95.4c2,34-0.9,87.9-0.9,87.9"/>
		<path class="st2 ld2" d="M323.1,141.1c9-2,16.4-1.7,25.6-3.4c-19.6,3.4-21.6,13.4-26,23.8c9.8-0.7,19.4-1.3,32.4,2.7
			c-16-1-17.5,9.1-27,14c9.2-1.6,14.1-6.2,23.4-6.3"/>
		<path class="st3 ld3" d="M334.7,121c3,15.5,5.4,56.6,7.4,61.6"/>
		<path class="st4 ld4" d="M381.5,124.9c12.9-0.7,16.1-3.6,28.8-1.6c-5.5,2.7,3.8,4.7-0.4,5.5c-11.8,2.4-19.7,16.7-30.9,19.3"/>
		<path class="st5 ld5" d="M379,146.1c12.5,2.6,15.1,0.4,25.4,3.6"/>
		<path class="st4 ld6" d="M405.8,150.9c-13.4,0.4-22.9,17.5-27.5,19c10.1-1.7,17-5.7,28.8,0.2"/>
		<path class="st2 ld7" d="M393.6,110.8c6.4,15.9,0.8,58.3-4.8,70.6"/>
		<path class="st2 ld8" d="M314.3,116.8c-6.1,8.4-12.6,47.5,1.8,69c-5.3,10.4,77.2,7,108.2-4.3"/>
		<path class="st6 ld9" d="M420.4,102c4.3,13.5,6.5,67.9-2.9,75.1"/>
		<path class="st7 ld10" d="M281.4,221.1c29.7-6.4,136-46.7,163.9-15.8c-79.5-17.1-93.7,17.4-105.5,22.5"/>
		<path class="st7 ld11" d="M333.9,224.7c-3.7,9.5,9.4,13.3,9.4,13.3"/>
		<path class="st7 ld12" d="M359,218.6c23-5.3,32-2.3,39.5,2.3"/>
		<path class="st8 ld13" d="M389.5,221.9c-22.3,12.3-24.6,24.1-39.8,29.4"/>
		<path class="st8 ld14" d="M342,257c3.8,5.2,10.3,6.3,10.3,6.3"/>
		<path class="st9 ld15" d="M370.2,241.1c26.6-9.7,53.9,1.6,0.8,20.1"/>
		<path class="st10 ld16" d="M305.4,280.6c41.7-8.6,103.3-29.2,127.9-2.4c-14.1-0.5-41.7,4.4-49.7,8.7"/>
	</g>
</g>
<g>
	<defs>
		<path id="SVGID_3_" d="M445.2,400.2c-3.6-6-6.6-12.5-13.2-15.9c-2-1-4.2-1.8-6.3-2.7c-1.1-0.4-2.4-0.5-3.4-1.1
			c-2.9-1.7-6-2.1-9.4-1.9c-7.1,0.5-14.2,0.3-21.3,0.4c-0.6,0-1.1,0-1.9,0c0.9-0.7,1.6-1.1,2.2-1.6c3.6-3,7.3-5.9,10.7-9.2
			c1.2-1.2,2.2-3,2.5-4.7c0.7-4.3-0.9-8-4.2-10.6c-3.5-2.8-7.3-5.1-11-7.6c-0.7-0.5-1.5-0.9-2.3-1.4c0.1-0.3,0.2-0.5,0.2-0.6
			c0.2-0.2,0.4-0.5,0.6-0.7c2-2.4,4.7-3,7.6-3.6c1.8-0.4,3.8-0.7,5.4-1.6c5.1-2.8,7.4-7.8,9.3-13c0.6-1.7,0.5-3.8,0.2-5.6
			c-1.1-6.3-4.7-11.3-9.1-15.6c-1.4-1.3-3.6-2.1-5.5-2.5c-3.6-0.7-7.3-0.2-10.7,1.1c-4.6,1.8-9.2,3.6-13.7,5.5
			c-0.2,0.1-0.4,0.4-0.6,0.6c-0.9,1-1.6,2.2-2.7,2.9c-3.3,2-6.8,3.9-10.3,5.8c-0.4,0.2-1,0.1-1.5,0.2c0-0.5-0.1-1-0.1-2.1
			c-5,3.1-9.7,6.1-14.5,9.1l0.2,0.4c0.9-0.4,1.8-0.9,2.7-1.3c-0.3,0.7-0.8,1.1-1.4,1.5c-4,2.8-8,5.5-11.9,8.5
			c-3.5,2.7-6.6,5.8-10.1,8.5c-2.3,1.8-5,3-7.5,4.5c0-0.1-0.1-0.1-0.1-0.2c0.4-0.5,0.7-1,1.1-1.5c-0.1-0.1-0.3-0.2-0.4-0.4
			c-2.4,2.7-4.8,5.4-7.2,8.1c-1.2,1.3-1.1,2.4,0,3.7c1.2,1.5,2.1,3.1,3.3,4.7c-2.1,0.6-1.9,1.8-1.5,3.1c0.3,1.3,0.7,2.5,0.7,3.8
			c0.1,2.6,0,5.1,0,7.7c0,5.5,0.9,10.9,2.9,16.1c0.6,1.4,0.8,3,1.1,4.5c0.1,0.5-0.1,1.4-0.5,1.7c-2.2,1.6-4.4,3.3-6.8,4.5
			c-5.5,2.5-11.2,4.6-16.8,7c-2.2,0.9-4.5,1.9-6.5,3.2c-2.8,1.8-5.4,3.8-8,5.9c-0.6,0.5-1.3,1.5-1.2,2.3c0,2.7,1,5,3.7,6.2
			c0.6,0.3,1.2,0.7,1.5,1.2c1,2,2.9,2,4.7,2.2c1.5,0.1,3.1,0.1,4.5,0.4c2.9,0.5,4.2-0.1,5.3-2.8c0.2-0.6,0.6-1.2,1.2-2.3
			c-3.5,0.8-7.8,1.7-7.9,0.8c-0.1-0.9,1.1-1.4,2.8-2.2c7.6-3.8,15.1-7.5,22.8-11.1c2.7-1.3,5.6-2.4,8.4-3.5c0.6-0.2,1.3-0.2,2-0.3
			l0.1,0.4c-8.4,4.9-16.9,9.7-25.3,14.6c0.1,0.2,0.2,0.4,0.3,0.6c0.7-0.2,1.4-0.5,2.1-0.7c1.3-0.4,2.6-0.7,3.9-1.1
			c1.6-0.5,3.4-0.6,4.7-1.5c4.7-3.7,10.4-5.4,15.5-8.5c2.5-1.5,3-1.4,5.1,0.7c3.8,3.8,7.7,7.4,12.5,9.9c0.6,0.3,1.4,0.3,2.1,0.4
			c-0.1-0.8-0.1-1.7-0.4-2.5c-0.2-0.5-0.9-0.8-1.3-1.2c-0.5-0.5-0.9-1.1-1.3-1.7c-0.6-0.9-1.1-1.9-1.7-2.9c-0.5-0.8-1.1-1.5-1.7-2.2
			c2,0.8,2.7,4.1,6,3c-0.5-0.9-0.8-1.6-1.2-2.1c-0.9-1.4-1.7-2.8-2.8-4.1c-3.1-3.9-3.1-3.8,1.4-6c1.3-0.6,2.4-1.5,3.6-2.2
			c-0.1-0.3-0.2-0.6-0.3-0.9c-1.9,0.5-3.7,1-5.6,1.5c0-0.2-0.1-0.4-0.1-0.6c6.3-2.5,13.1-2.7,19.9-4c-0.2,0.5-0.3,0.9-0.5,1.2
			c-1.9,3.8-3.9,7.5-5.7,11.3c-1.6,3.3-2.6,6.7-2.4,10.5c0.2,3.3,0,6.7,0,10c0,0.5,0.1,1.2-0.2,1.4c-1.7,1.2-3.4,2.3-5.2,3.4
			c-0.7,0.4-1.6,0.4-2.3,0.8c-2.4,1.3-4.7,1.4-7.1-0.1c-0.5-0.3-1.1-0.7-1.7-0.9c-4.6-1.2-9.2-2.6-13.8-3.3
			c-6.1-0.9-11.1,1.8-15.2,6.3c-3.3,3.6-5,8.1-6.8,12.5c-0.6,1.5-0.5,3.4-0.2,5.1c0.7,4.2,3,7.4,6.1,10.2c1.4,1.2,3.2,2.1,4.2,3.6
			c2.2,3.2,5.4,5.4,8.9,4.3c3.6-1.1,7.4-1.8,11-3c3.7-1.1,6.7-4.4,10.4-5.7c2.7-0.9,5.4-1.7,8.1-2.8c2.7-1.1,5.3-2.5,8-3.6
			c6-2.5,12-5.1,18.8-5.3c3.7-0.1,5-1.7,5.1-5.5c0-3,1.2-5.3,3-7.4c3.8-4.2,7.7-8.3,11.8-12.6c-3.9,2.3-7.8,4.5-11.6,6.7
			c2.1-2.7,4.5-4.9,7.8-5.8c4.5-1.2,8.9-2.4,13.4-3.6c0.7-0.2,1.4-0.3,2.1-0.5c1.1-0.3,1.6,0.2,1.6,1.2c-0.1,6.4-0.7,12.8-3.3,18.7
			c-2.1,4.7-4.4,9.3-6.7,14c-0.2,0.4-0.7,0.9-1.1,0.9c-2.5-0.2-5.1-0.4-7.2-2.2c-0.5-0.4-1-0.7-1.5-1c-0.1,0.1-0.2,0.2-0.3,0.3
			c1.1,1.5,2.1,3,3.2,4.5c-0.1,0.1-0.2,0.2-0.4,0.3c-2.5-2.7-5-5.5-7.5-8.2c0.7,1.6,1.5,3.1,2.6,4.4c4.8,5.6,9.7,11.1,14.6,16.7
			c1.1,1.2,2.1,2.5,3,3.8c2.5,3.6,5.2,6.9,9.8,8.1c2.1,0.5,3.9,0.4,5.6-0.8c3.1-2.2,6.2-4.5,7.4-8.4c0.4-1.5,1.5-2.7,2.1-4.1
			c1.4-3,3.1-5.9,3.9-9.1c2.8-10.3,3.3-20.8,0.1-31.2c-2.9-9.4-9-15.8-18.2-19.5c-3-1.2-5.4,0.6-8.1,0.8c-0.2,0-0.4,0.3-0.5,0.5
			c-0.7,2.3-2.6,3.3-4.4,4.5c-5.5,3.8-11.2,7.5-16.8,11.2c-1.1,0.7-2.2,1.4-3.4,2.2c-0.4-2.1-0.6-4-1.3-5.8c-0.9-2.3-1-4.7,0-6.8
			c1.6-3.3,1.5-6.6,0.6-9.9c-1.6-5.6-4.5-10.4-10.2-12.8c-0.4-0.2-1-0.7-1-1.1c0-0.3,0.6-0.8,1-1c0.6-0.3,1.2-0.6,1.8-0.7
			c4.9-0.7,9.8-1.7,14.8-2.1c5.2-0.4,10.5-0.1,15.7,0c0.7,0,1.3,0.1,2,0.2c0.1,0.2,0.2,0.3,0.3,0.5c-1.8,2.1-3.3,4.5-5.3,6.3
			c-2.6,2.3-5.7,4.1-8.5,6.2c-0.7,0.5-1.4,1-2.1,1.5c0.1,0.1,0.1,0.2,0.2,0.3c1.1-0.3,2.2-0.6,3.3-0.9c0,0.1,0.1,0.2,0.1,0.3
			c-2.4,1.5-4.8,3-7.2,4.6c0.8,0.1,1.4-0.1,2-0.1c5.1-0.1,10.1-0.1,15.2-0.3c1.5-0.1,3.3-0.3,4.5-1.1c2.2-1.4,4.5-1.6,6.9-1.3v1.4
			c2.8-1,5.4-1.9,7.9-2.9c2-0.8,3.8-1,5.8,0.1c2.7,1.4,5.8,1.5,8.5,0.4c2-0.8,3.7-0.9,5.7-0.3c2.1,0.6,4.1,0.1,5.5-1.5
			C446.5,403.7,446.1,401.8,445.2,400.2z M337.6,460.9c-0.1-0.2-0.2-0.4-0.3-0.6c2.2-1.7,4.9-2.1,7.5-2.9c0.1,0.2,0.1,0.3,0.2,0.5
			C342.5,458.8,340,459.9,337.6,460.9z M349.8,447c-9,3.3-17.9,6.6-26.9,9.8c-4.4,1.5-5.4,0.7-4.9-4c0.3-3.5,1.7-6.7,4.3-9
			c1.9-1.7,4.3-3,6.7-4c2.2-0.9,4.5-0.6,6.3,1.1c3.3,3.2,7.2,4.8,11.7,4.9c1.2,0,2.3,0.3,3.9,0.4C350.3,446.7,350.1,446.9,349.8,447
			z M352,451.7c-0.1-0.1-0.2-0.3-0.2-0.4c1.4-1.1,2.7-2.3,4.5-2.7c0.1,0.2,0.2,0.4,0.4,0.6C355.1,450.1,353.5,450.9,352,451.7z
			 M324.9,390c-3.5-3.7-5.5-8.1-6.1-13.1c-0.2-1.7-0.7-3.3-1.2-5c-1-3.6-1-7.2-0.2-10.8c0.2-0.7,1.5-1.5,2.4-1.7
			c2.7-0.6,5.4-0.8,8.2-1.2v1.4c1-0.4,1.9-0.8,2.8-1c1.3-0.3,2.5-0.6,3.9,0.4c1,0.7,2.9,0.3,4.3,0.3c1.3,0,2.6,0,4.1,0
			c-0.2,0.7-0.4,1.1-0.7,2c6.4-2.2,12.4-4.2,18.2-6.2C357.4,363.1,332.6,387.6,324.9,390z"/>
		</defs>
	<clipPath id="SVGID_4_">
		<use xlink:href="#SVGID_3_"  style="overflow:visible;"/>
	</clipPath>
	<g class="st11">
		<path class="st12 ld17" d="M394.7,317.4c-12,9.6-55.7,25.7-72.4,25.7"/>
		<path class="st3 ld18" d="M313,362.9c0,28.1,9.3,35.2,28.6,56.2"/>
		<path class="st13 ld19" d="M390.2,352.8c-19.3,27.5-87.4,36.7-108.4,55"/>
		<path class="st14 ld20" d="M287.6,420.8c22.7-21.2,114.5-69.7,149.9-23"/>
		<path class="st14 ld21" d="M431.2,391c-3.9,9.7-41.5,9.4-41.5,9.4"/>
		<path class="st6 ld22" d="M363.1,404.5c0.2,13.5,12.2,34.9-21.1,25.9c0,0-43.5-11.9-37.3,35.5"/>
		<path class="st1 ld23" d="M304.7,465.8c0,0,68.2-8.3,92-45.7"/>
		<path class="st15 ld24" d="M404.9,409.5c13.4,10.2,15.7,56.4,5.6,63c-6.6-6.7-18.7-12-18.7-12"/>
	</g>
</g>
<g id="_x33_">
	<defs>
		<path id="SVGID_5_" d="M518.1,550.9c-1,0.1-2-0.1-2.7-0.5c1.1-0.4,2.6-0.5,2.2-1.9c0.4-0.5-0.1-1.3-0.7-1l0.3-0.3
			c-0.9-1-2.3-0.6-3.5-0.9c-4.9,0-10.1-1.1-14.7-0.5c-1.4-2-1.4,1.7-2.9-0.2c-0.4,0.2-0.6,0.9-1.2,1.3c-0.7,0.1-1.5-0.1-1.7-0.5
			c0-0.3,0.4-0.3,0.7-0.3l-0.3-0.5c0.6,0,0.6-0.5,0.9-0.9l0.3,0.3l0.2-0.2c-0.2-0.1-0.4-0.3-0.4-0.6l-0.4,0.5
			c-0.7-0.7,0.5-1.4-0.9-1.7l0.4-0.3c-2.7-0.5-5.1-0.2-7.1-2.4c-1.7,0.1-3.4-0.8-4.7-1c0.1-0.1,0.1-0.2,0.3-0.1l-0.3-0.8
			c0.3-0.3-0.1-0.6,0.1-0.8c-0.5-1.5-2.7-0.5-4-1.1c-5.1-4.4-12.2-5.3-17.8-9c-1.8-0.8-3.7-2.2-5.4-1c-0.9-0.3-2.5,0.1-2.7-0.9
			c-2-0.3-4-0.5-5.9-1.1c-3.7-3.3-6.8-7.4-11.4-10.1l-3.6,0.3c-3.2-0.7-6.4,1.3-8.9-0.5c-0.4,0.5-1,0.7-1.7,0.6
			c-0.3-0.1-1.2-0.4-1-1.1c-10.5,1.6-19.2-3.4-28.7-5.1c-7-7.6-15-15.5-24-21.1l-5-0.5c-1.1-0.2-2.3,0.3-3.3-0.1
			c-2.3,0.4-3.9-1.5-6.2-1.5c-1.3,0.2-2.3,0.7-3,1.7c-2.2,0.6-1.9,2.9-2.6,4.7c0.4,0.9-1.6,0.9-0.5,1.4c-0.9,1.1-2.2,1.7-3.5,1.4
			c-2.4,2.4-5.9,2.7-9,2.9c-2.2,1-5.4,1.1-6.4,3.4l-0.1-0.2c-2.9,2.7-6.4,6.3-10.6,7.8c-0.2-0.1-0.1-0.3-0.1-0.5
			c-3-0.7-4.9,2.4-7.3,3.6c-0.6-0.5-1.4,1-2.1,0c0.1-0.1,0-0.3,0.2-0.5c-2.2,0.5-3.8,2.5-5.4,4c-3.5,1.3-4.3,6.9-9,5.5
			c-0.2,0.2-0.5,0.3-0.4,0.6c-1.7-0.2-3.9,0.4-5.7,0.7c-1.1,1.1-2.4,1.3-3.8,1.9c0.4,1.2-1.4,0.5-2.1,0.8c-1.2,1.1-3.3,1.6-4.7,2.1
			c-0.1,0.3-0.6,0.6-0.1,1c-0.2,0.8-1.2,1.4-2.1,1.1c-2.6,2.8-7.7,4.1-8.8,8c-2.4,1.3-2.2,4.1-4.6,4.8l0.5,0.5l-0.7,0.4
			c-0.9,1,0.9,2,0.5,3h-0.5l1.5,0.8c0.1,1.7,2.7,3,2.1,4.8c1.1,2.4,2.7,4.8,3.3,7.2c1.6-0.2,1.8,2.2,3.5,1.4l0.1,0.5
			c0.4,0.5-0.4,1.9,0.5,2.4c0.6,0.1,1-0.1,1.2-0.5c2.2-0.1,2.7,1.5,4.8,1.1c-0.6,0.6-1.2,1.2-1.7,1.6l0.4,0.5c0.5-0.6,1.5,0,1.9-0.9
			h-0.3c-0.4-0.2-0.4-0.5-0.4-0.8c0.7-0.2,1.4-1.7,2.2-0.7c0.1-0.2,0.1-0.5,0.4-0.6c1-0.5,0.8,0.9,1.7,0.6c0.1-0.1,0.4-0.3,0.4-0.5
			c-0.4-0.5,0.7-1.5,1-2.3c0.4-0.7,1.2-0.1,1.6,0.2l0.5-0.7c1.1,0.3,2.1,0.1,3.1,0.2c0.8,0.3,0.3,0.7,0.2,1.3c0.4,0.6,1.2,1,2,0.6
			c1.3,0.4,0.4,1.2,1.4,1.8l2.2-1c1.2-5.8,8.2-6.6,12.3-9.3c2.8,0.2,5.4-2.2,8.3-2.2c0-0.2,0.1-0.4-0.1-0.5c0.1-0.5,0.9-0.9,1.4-0.3
			c1-0.5,2.9,0.4,2.8-0.8c0.2-0.1,0.3,0.2,0.5,0.3c0.6-0.4,1.2-0.4,1.4-1.1l1.2,0.5c0.9-1.5,1.6-2.5,2.2-4c0.6-0.5,0.9-2.2,1.9-2.6
			c0.2-0.4,1.8-1.5,0.9-2.4c0.2-0.7,1.2-0.5,1.6-1.1l-0.2-0.2c1.5-1.3,2.3-4.1,4.6-4.8c0.3-0.5,1.4-0.1,1.1-0.9l-0.5,0.5
			c-0.6,0.4-1.1-0.1-1.6-0.1c-0.2,1.1-1.5,1.5-2.2,1.8c0.9-1.1,2.1-2.1,2.6-3.2c4.3-3.7,8.7-6.7,14.2-7.2c-0.2-0.2-0.5-0.3-0.4-0.7
			l-1.1,0.3c-0.6-0.5-2.1-0.1-2.4-1.3c0.9-0.5,1.7-0.7,2.1-1.7c2.4-1,5.3-1.8,7.9-2.6c1.2-0.3,2-0.1,2.9-1.1l-0.9-0.5
			c0.5-1.8,1.2-3.8,2.8-5.2c1.4-0.7,2.8,1.3,3.8,0.5c2.6,0.8,4.6,1.5,7.1,2.5c5.6,4.9,12,8.7,16.5,14.8c1,1.5,2.4,2.8,3.7,4
			c1.3,1.6,2.8,3.6,4.3,5c1.2,0.7,2.2,1.9,3.8,1.9l4.3,4.5c3.5-0.1,7.6,2,10.9,2c0.6,1,1.7,0.1,2.4,0.7l-0.2,0.1
			c1.2,0.3,2.7,1.3,4.1,0.9v0.3c0.2,0,0.4,0,0.6,0.2V549c2.1,0.8,4.8,1.9,7.2,1.4c1.5,0.1,3.8,0.1,4.9-0.6c3,0.9,6.2-0.9,9.3,0.1
			c0.4,1.9,1.9,2.9,2.8,4.5c4.4,0.8,4.1,5.4,7.5,7.6c4-0.9,7.9,0.2,12.1,0.3c0.1-0.7,1-0.7,1.4-0.7c-0.3-0.7-1.6-0.5-1.6-1.4
			l-0.5,0.3c0.2-0.4-0.6-0.9,0.2-1.3c1.1-0.3,2,0.5,2.6,0.7l-0.2-0.5c0.3-0.5,0.8-0.5,1.2-0.8c0.6,1.2,2.7,0.8,3.3,1.8
			c2.8,0.3,5.3,1.9,8.1,1.9c-0.3-0.4-0.7-0.6-1.2-0.8c0.3-0.3,0.1-0.8,0.1-1.1c1.1-1.1,1.7,0.7,2.7,0.5l-0.1-0.6
			c1.5-0.7,2.8,0.6,4,1.3c-0.6-0.9-1.8-1.5-1.4-2.8c0.5-0.3,1.2-0.7,1.7-0.3l-0.7-0.5c0.3-0.2,0.3-0.5,0.4-0.7
			c-0.4,0.3-0.7,0-1.2-0.1c-0.6-1.6,1-0.3,1.1-1.6l0.4,0.3c1.2,0.9,1.6-0.8,2.6-1.3c2.6,0.2,0.4-1.8,1.7-2.7
			c0.3-0.4,1.2-1.3,1.9-0.5c0.7-0.9,1.7,0.4,2.5-0.5l-0.4-0.7c0.5-0.5,1-0.9,1.7-0.9c0.7,0.9,3.2,0.8,2.3,1.9c0.8,0,0.9,0.6,1,1.1
			c1.5,0.5,3.3,1.1,5.1,1.3c0.5,1.7,3.3,0.9,4.9,1.1c0.7-1.5,2-0.9,3.5-1.1c-0.6-0.7,0.4-0.7,0.5-1.3l1.1,0.8
			c0.6,0.2,0.8-0.9,1.4-0.3c0.2-0.1,0.3-0.3,0.5-0.3v-0.3l2.6-0.2C516.7,551.7,518.4,552.3,518.1,550.9z M492.7,543.8l0.3-0.3
			l0.7,0.6L492.7,543.8z"/>
	</defs>
	<clipPath id="SVGID_6_">
		<use xlink:href="#SVGID_5_"  style="overflow:visible;"/>
	</clipPath>
	<path class="op8 ld25" d="M284.1,547.2c100-92,141,15,221.5,15.5"/>
</g>
<path id="_x34_" class="st17 op" d="M274.4,97.4c-0.5,1.4-1.1,1.6-2.2,1.6h-16.6v5h7.9v4.4h-7.2v30.9h2.8l0,3.9l0,0
	c-0.2,0.6-0.7,0.9-1.3,0.9h-2.5v4.8h5.8l0.1,0c1.6,0,2.9,0,2.9-2l0-7.6h3.9v7.6c0.1,1.9,1.3,2,2.9,2l0.1,0h6v-4.8h-2.5
	c-0.6,0-1-0.3-1.3-0.9l0,0l0-3.9l0.2,0c2.8-0.1,4-1.4,4-4v-26.8h-8v-4.4h5.6c2,0,3.2-0.4,4.1-1.5c1-1.2,1.6-2.1,1.8-5.2H274.4z
	 M272.4,113.3v4.2h-7.2l0,4.6h7.3v3.8h-7.2v4.6h7.2v1.8c0,1.2-0.7,1.9-1.7,1.9h-9.3v-21H272.4z M249.3,104h1.7V99h-18v45.6
	c0,2.9,1.4,4.3,4.1,4.3H251v-5.1h-1.7v-4.1l0.2,0c2.2-0.1,3.2-1.3,3.2-4v-27.3h-3.4V104z M244.3,108.5h-3.4v31.4h3.4v4h-4.8
	c-0.6,0-1.7-0.6-1.7-1.8V104h6.5V108.5z M248.5,133.2c0,1.3-0.4,2-1.2,2h-2.3v-22.2h3.4V133.2z M275.5,175.9v-18.1h-40.9v23.3h35.6
	C273.7,181.1,275.5,179.4,275.5,175.9z M269.3,167.1h-23.8v5h23.8v0.9c0,1.5-0.6,3.3-1.8,3.3h-27.1v-13.4h28.9V167.1z M233,184.6
	v22.9h38.9c3.6,0,5.3-1.7,5.3-5.2v-17.7H233z M271.3,193.8h-6.4v5h6.4v0.9c0,1.4-0.6,2.9-1.8,2.9H239v-12.9h32.3V193.8z
	 M261.1,193.8h-17.3v5h17.3V193.8z"/>
<path id="_x35_" class="st18 op" d="M514,362.5c-0.6,0.4-1.6,1.1-2.8,2.1l-4.2,3.2l-4,3.1c1.4,0.1,3.1,0.1,5.3,0.1c1.6,0,2.7,0,3.3-0.1
	c0.6-0.1,1-0.3,1.4-0.7c0.4-0.4,0.6-0.9,0.6-1.5c0-0.3,0.1-0.4,0.2-0.4c0.1,0,0.1,0,0.2,0.1c0,0.1,0.1,0.5,0.1,1.4
	c0,0.9,0.1,1.6,0.1,2.2l0,1.4v0.2c0,0.2-0.1,0.3-0.2,0.3c-0.1,0-0.2-0.1-0.2-0.3c-0.1-0.7-0.3-1.2-0.6-1.5c-0.3-0.3-0.8-0.5-1.3-0.5
	s-2.6-0.1-5.9-0.1l-6-0.1c-0.3,0-0.5-0.1-0.5-0.2c0,0,0-0.1,0-0.1c0-0.1,0.4-0.4,1.1-0.9c0.7-0.5,1.6-1.2,2.6-2
	c1-0.8,1.8-1.4,2.5-1.9c1.4-1,2.2-1.6,2.5-1.9c1.2-1,1.9-1.6,2.2-1.7c0.2-0.1,0.3-0.2,0.3-0.3c0-0.1-0.1-0.1-0.4-0.1l-1.7,0
	c-2.2,0-4,0.1-5.3,0.2c-1.3,0.1-2.1,0.2-2.3,0.3c-0.2,0.1-0.5,0.3-0.7,0.6c-0.2,0.3-0.3,0.6-0.3,0.9c0,0.4-0.1,0.7-0.3,0.7
	c-0.1,0-0.2-0.1-0.2-0.4l0-4.9c0-0.2,0.1-0.3,0.2-0.3c0.1,0,0.2,0.1,0.2,0.3c0,0.8,0.2,1.4,0.6,1.7c0.4,0.3,1.3,0.4,2.6,0.4l4-0.1
	c2.3,0,3.7-0.1,4.1-0.2c0.4-0.1,0.9-0.4,1.5-0.8c0.6-0.4,0.9-0.9,1-1.4c0-0.2,0.1-0.3,0.2-0.3c0.1,0,0.2,0.1,0.2,0.4L514,362.5z
	 M500.8,379.8c-0.5-0.3-0.9-0.7-1.2-1.2c-0.3-0.5-0.4-1-0.4-1.6c0-0.7,0.2-1.2,0.6-1.6c0.4-0.4,0.9-0.6,1.6-0.6c0.5,0,1,0.1,1.4,0.4
	c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.8,0.7,1.6c0.4,1.1,0.7,1.7,1,1.9c0.3,0.2,0.6,0.3,1,0.3c0.7,0,1.2-0.1,1.6-0.4
	c0.4-0.3,0.6-0.7,0.6-1.2c0-0.4-0.1-0.7-0.3-1c-0.2-0.2-0.6-0.4-1.3-0.5c-0.7-0.1-1-0.4-1-0.8c0-0.1,0.1-0.3,0.2-0.5
	c0.2-0.2,0.4-0.3,0.7-0.3c0.6,0,1.1,0.3,1.5,0.8c0.4,0.6,0.6,1.3,0.6,2.3c0,0.8-0.1,1.4-0.4,1.9c-0.2,0.5-0.5,0.8-0.8,0.9
	c-0.3,0.1-0.9,0.2-1.8,0.2l-3-0.1c-1.2,0-2,0.1-2.5,0.2c-0.5,0.1-0.7,0.4-0.7,0.7c0,0.1,0,0.2,0,0.3l0,0.2c0,0.2-0.1,0.2-0.2,0.2
	c-0.3,0-0.4-0.7-0.4-2c0-0.4,0.1-0.6,0.3-0.8C499.7,379.9,500.1,379.8,500.8,379.8z M505.2,379.8c-0.3-0.2-0.6-0.7-1.1-1.4
	c-0.4-0.7-0.8-1.2-1.2-1.5c-0.4-0.3-0.8-0.5-1.4-0.5c-0.5,0-0.9,0.1-1.2,0.4c-0.3,0.3-0.5,0.6-0.5,1c0,0.4,0.1,0.8,0.4,1.1
	s0.6,0.6,1,0.8c0.4,0.2,1,0.2,1.8,0.2C503.7,379.9,504.4,379.8,505.2,379.8z M508,385.9c-1.7-0.1-3.3-0.1-4.9-0.1
	c-1.2,0-2,0.1-2.4,0.4c-0.4,0.3-0.6,0.6-0.6,1.1c0,0.4,0.1,0.8,0.4,1.2c0.1,0.2,0.2,0.3,0.2,0.3c0,0.1-0.1,0.1-0.2,0.1
	c-0.2,0-0.5-0.2-0.9-0.7c-0.4-0.5-0.6-1.1-0.6-1.8c0-0.7,0.3-1.2,0.8-1.6c0.5-0.3,1.3-0.5,2.4-0.5c1.1,0,3,0.1,5.7,0.2
	c0,0,0-0.4,0.1-1l0-0.6c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.3c0.1,0.3,0.4,0.7,1,1.4c0.5,0.7,1.3,1,2.3,1.1
	c0.3,0,0.4,0.1,0.4,0.2c0,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0c-0.3,0-0.6,0-1,0l-1.8,0l0,2.2c0,0.4,0,0.6-0.1,0.6
	c-0.3,0-0.6-0.1-0.7-0.2C508.1,388.2,508.1,387.4,508,385.9z M508.8,391.9c-0.5,0-1.9,0.1-4.2,0.1c-1.5,0-2.4,0-2.9,0.1
	c-0.5,0.1-0.9,0.2-1.2,0.5c-0.3,0.3-0.5,0.6-0.5,1c0,0.5,0.3,1,0.8,1.5c0.5,0.5,1,0.8,1.6,0.9c0.6,0.1,1.9,0.2,4,0.2
	c1,0,1.5-0.1,1.7-0.2c0.2-0.1,0.3-0.4,0.3-0.8c0-0.2,0.1-0.2,0.2-0.2c0.1,0,0.1,0,0.2,0.1c0,0,0,0.2,0,0.5v2
	c-0.8,0.1-1.7,0.1-2.8,0.1l-4.2-0.1c-0.9,0-1.4,0.1-1.6,0.2c-0.2,0.1-0.4,0.3-0.4,0.6c0,0.2-0.1,0.3-0.2,0.3c-0.1,0-0.2-0.2-0.2-0.5
	v-1.6c0-0.3,0.1-0.4,0.3-0.4c0.1,0,0.1,0,0.2,0c0.2,0,0.5,0,0.7,0c0.2,0,0.5,0,0.9,0c-0.7-0.5-1.3-1-1.7-1.6
	c-0.4-0.6-0.6-1.1-0.6-1.7c0-0.5,0.1-0.9,0.4-1.3c0.3-0.3,0.6-0.6,1-0.7c0.4-0.1,1.4-0.2,2.9-0.2c1.6,0,2.8-0.1,3.7-0.1
	c0.5,0,0.8,0,0.9-0.1c0.1,0,0.2-0.2,0.2-0.4c0-0.3,0.1-0.4,0.2-0.4c0.1,0,0.2,0.2,0.2,0.5V391.9z M506.4,402.5
	c0.8,0.4,1.5,0.9,1.9,1.4c0.4,0.5,0.6,1,0.6,1.5c0,0.4-0.1,0.7-0.4,1c-0.3,0.3-0.6,0.4-1,0.4c-0.7,0-1.1-0.3-1.1-0.8
	c0-0.3,0.2-0.5,0.6-0.6c0.5-0.2,0.8-0.5,0.8-0.9c0-0.3-0.2-0.7-0.6-1c-0.4-0.4-0.9-0.6-1.4-0.7c-0.5-0.1-1.5-0.2-3.1-0.2
	c-1.5,0-2.3,0.1-2.5,0.2c-0.2,0.1-0.4,0.4-0.4,0.8c0,0.1-0.1,0.2-0.2,0.2c-0.2,0-0.2-0.2-0.2-0.5V400c0-0.2,0.1-0.3,0.2-0.3
	c0.1,0,0.2,0.2,0.2,0.5c0,0.4,0.2,0.7,0.4,0.8c0.3,0.1,1.2,0.2,2.9,0.2c2.5,0,3.9,0,4.4-0.1c0.5-0.1,0.8-0.4,0.8-1
	c0-0.2,0.1-0.4,0.2-0.4c0.1,0,0.1,0,0.2,0.1c0,0,0.1,0.4,0.1,0.9l0.1,1.9C508.5,402.4,507.7,402.5,506.4,402.5z M500.8,412.9
	c-0.5-0.3-0.9-0.7-1.2-1.2c-0.3-0.5-0.4-1-0.4-1.6c0-0.7,0.2-1.2,0.6-1.6c0.4-0.4,0.9-0.6,1.6-0.6c0.5,0,1,0.1,1.4,0.4
	c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.8,0.7,1.6c0.4,1.1,0.7,1.7,1,1.9c0.3,0.2,0.6,0.3,1,0.3c0.7,0,1.2-0.1,1.6-0.4
	c0.4-0.3,0.6-0.7,0.6-1.2c0-0.4-0.1-0.7-0.3-1c-0.2-0.2-0.6-0.4-1.3-0.5c-0.7-0.1-1-0.4-1-0.8c0-0.1,0.1-0.3,0.2-0.5
	c0.2-0.2,0.4-0.3,0.7-0.3c0.6,0,1.1,0.3,1.5,0.8c0.4,0.6,0.6,1.3,0.6,2.3c0,0.8-0.1,1.4-0.4,1.9c-0.2,0.5-0.5,0.8-0.8,0.9
	c-0.3,0.1-0.9,0.2-1.8,0.2l-3-0.1c-1.2,0-2,0.1-2.5,0.2c-0.5,0.1-0.7,0.4-0.7,0.7c0,0.1,0,0.2,0,0.3l0,0.2c0,0.2-0.1,0.2-0.2,0.2
	c-0.3,0-0.4-0.7-0.4-2c0-0.4,0.1-0.6,0.3-0.8C499.7,413,500.1,412.9,500.8,412.9z M505.2,412.9c-0.3-0.2-0.6-0.7-1.1-1.4
	c-0.4-0.7-0.8-1.2-1.2-1.5c-0.4-0.3-0.8-0.5-1.4-0.5c-0.5,0-0.9,0.1-1.2,0.4c-0.3,0.3-0.5,0.6-0.5,1c0,0.4,0.1,0.8,0.4,1.1
	c0.3,0.3,0.6,0.6,1,0.8c0.4,0.2,1,0.2,1.8,0.2C503.7,413,504.4,413,505.2,412.9z M514.8,419.3c-0.9,0-1.9,0-2.8,0l-7,0l-3.4,0
	c-0.6,0-1.1,0.1-1.4,0.2c-0.3,0.2-0.5,0.5-0.5,1.1c0,0.2-0.1,0.3-0.2,0.3c-0.1,0-0.2-0.1-0.2-0.3c0,0,0-0.1,0-0.2
	c0.1-0.7,0.1-1.5,0.1-2.5c0-0.6,0-1.1-0.1-1.4c0-0.1,0-0.1,0-0.2c0-0.2,0.1-0.3,0.2-0.3c0.1,0,0.2,0.1,0.2,0.3c0,0.6,0.2,1,0.6,1.2
	c0.4,0.2,1,0.3,1.9,0.3l6.4,0c2.1,0,3.3,0,3.7-0.1c0.4,0,0.7-0.2,1-0.4c0.3-0.2,0.4-0.6,0.5-1c0-0.3,0.1-0.4,0.2-0.4
	c0.1,0,0.2,0.1,0.2,0.3c0.1,1.2,0.3,2.2,0.5,2.8V419.3z M507.3,434c-0.4,1.3-0.9,2.2-1.5,2.8s-1.5,0.9-2.4,0.9c-1,0-2-0.4-2.8-1.2
	c-0.9-0.8-1.3-1.9-1.3-3.4c0-0.3,0-0.7,0.1-1.4c0-0.4,0-0.6,0-0.6c0-2.1,0-3.5-0.1-4.2c0-0.2,0.1-0.4,0.2-0.4c0.1,0,0.1,0,0.2,0.1
	c0,0.1,0.1,0.3,0.2,0.8c0.1,0.3,0.2,0.5,0.4,0.6c0.2,0.2,0.6,0.3,1.2,0.4c0.6,0.1,1.6,0.1,2.8,0.1c1.2,0,2.2,0,2.9,0
	c0.7,0,1.3,0,1.7,0c1.2,0,2.2,0,3-0.1c0.8-0.1,1.2-0.2,1.4-0.4c0.2-0.2,0.4-0.5,0.4-1c0-0.2,0.1-0.3,0.2-0.3c0.1,0,0.2,0.1,0.2,0.3
	l0,1.8c0,0.6,0,1.2,0,1.7c0.1,1.2,0.1,1.9,0.1,2.3c0,1.3-0.3,2.2-0.9,3c-0.6,0.7-1.4,1.1-2.5,1.1c-0.8,0-1.5-0.2-2.1-0.7
	C508.2,436,507.7,435.2,507.3,434z M506.8,430.6c-0.2-0.1-0.5-0.1-1-0.1l-3.5-0.1c-1.2,0-1.9,0-2.1,0.1c-0.1,0.1-0.3,0.3-0.4,0.6
	c-0.1,0.3-0.1,0.9-0.1,1.6c0,0.6,0.1,1.2,0.3,1.6c0.2,0.4,0.6,0.8,1.2,1.1c0.6,0.3,1.3,0.5,2.1,0.5c1.2,0,2.1-0.3,2.7-1
	c0.6-0.6,0.9-1.6,0.9-2.9C506.8,431.5,506.8,431.1,506.8,430.6z M513.5,430.6c-1.6-0.1-3-0.1-4-0.1l-1.7,0c-0.2,0-0.4,0-0.4,0.1
	c-0.1,0.1-0.1,0.2-0.1,0.5c0,1.3,0.3,2.3,0.8,3.1c0.5,0.8,1.4,1.2,2.6,1.2c1,0,1.8-0.3,2.3-0.9c0.5-0.6,0.8-1.3,0.8-2.2
	C513.8,431.8,513.7,431.2,513.5,430.6z M500.8,444.2c-0.5-0.3-0.9-0.7-1.2-1.2c-0.3-0.5-0.4-1-0.4-1.6c0-0.7,0.2-1.2,0.6-1.6
	c0.4-0.4,0.9-0.6,1.6-0.6c0.5,0,1,0.1,1.4,0.4c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.8,0.7,1.6c0.4,1.1,0.7,1.7,1,1.9
	c0.3,0.2,0.6,0.3,1,0.3c0.7,0,1.2-0.1,1.6-0.4c0.4-0.3,0.6-0.7,0.6-1.2c0-0.4-0.1-0.7-0.3-1c-0.2-0.2-0.6-0.4-1.3-0.5
	c-0.7-0.1-1-0.4-1-0.8c0-0.1,0.1-0.3,0.2-0.5c0.2-0.2,0.4-0.3,0.7-0.3c0.6,0,1.1,0.3,1.5,0.8c0.4,0.6,0.6,1.3,0.6,2.3
	c0,0.8-0.1,1.4-0.4,1.9c-0.2,0.5-0.5,0.8-0.8,0.9c-0.3,0.1-0.9,0.2-1.8,0.2l-3-0.1c-1.2,0-2,0.1-2.5,0.2c-0.5,0.1-0.7,0.4-0.7,0.7
	c0,0.1,0,0.2,0,0.3l0,0.2c0,0.2-0.1,0.2-0.2,0.2c-0.3,0-0.4-0.7-0.4-2c0-0.4,0.1-0.6,0.3-0.8C499.7,444.3,500.1,444.2,500.8,444.2z
	 M505.2,444.2c-0.3-0.2-0.6-0.7-1.1-1.4c-0.4-0.7-0.8-1.2-1.2-1.5c-0.4-0.3-0.8-0.5-1.4-0.5c-0.5,0-0.9,0.1-1.2,0.4
	c-0.3,0.3-0.5,0.6-0.5,1c0,0.4,0.1,0.8,0.4,1.1c0.3,0.3,0.6,0.6,1,0.8c0.4,0.2,1,0.2,1.8,0.2C503.7,444.2,504.4,444.2,505.2,444.2z
	 M509,450.2c-0.3,0-0.6,0-0.9,0c-0.2,0-0.6,0-1,0c0.7,0.7,1.2,1.2,1.4,1.5c0.2,0.4,0.3,0.8,0.3,1.2c0,1-0.5,1.9-1.4,2.7
	c-1,0.7-2.1,1.1-3.4,1.1c-1.4,0-2.5-0.4-3.4-1.3s-1.3-1.8-1.3-2.9c0-0.8,0.3-1.5,0.8-2.3c-0.9-0.1-1.9-0.2-3.1-0.2
	c-0.7,0-1.2,0-1.5,0.1l-0.4,0c-0.5,0.1-0.8,0.5-0.9,1c0,0.2-0.1,0.3-0.3,0.3l-0.1,0l0-0.1c0.1-0.8,0.1-1.5,0.1-2c0-1.2,0-1.9,0-2.2
	c0-0.2,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2c0.1,0.6,0.2,1,0.5,1.2c0.2,0.2,1.3,0.3,3.2,0.3c1.9,0,3.1,0.1,3.5,0.1l3.1,0l1.8,0
	c0.9,0,1.6-0.1,1.8-0.2c0.3-0.1,0.4-0.4,0.5-0.9c0-0.2,0.1-0.2,0.2-0.2c0.1,0,0.1,0,0.2,0.1c0,0.1,0.1,0.4,0.1,0.9L509,450.2z
	 M503.9,450.1c-1.4,0-2.3,0-2.6,0.1c-0.4,0.1-0.7,0.3-1.1,0.7c-0.3,0.4-0.5,0.9-0.5,1.5c0,0.8,0.4,1.5,1.2,2s1.7,0.8,2.9,0.8
	c1.3,0,2.3-0.3,3.1-0.8c0.8-0.5,1.2-1.1,1.2-1.9c0-0.7-0.3-1.3-1-1.7C506.4,450.3,505.4,450.1,503.9,450.1z M508,460.8
	c-1.7-0.1-3.3-0.1-4.9-0.1c-1.2,0-2,0.1-2.4,0.4c-0.4,0.3-0.6,0.6-0.6,1.1c0,0.4,0.1,0.8,0.4,1.2c0.1,0.2,0.2,0.3,0.2,0.3
	c0,0.1-0.1,0.1-0.2,0.1c-0.2,0-0.5-0.2-0.9-0.7c-0.4-0.5-0.6-1.1-0.6-1.8c0-0.7,0.3-1.2,0.8-1.6c0.5-0.3,1.3-0.5,2.4-0.5
	c1.1,0,3,0.1,5.7,0.2c0,0,0-0.4,0.1-1l0-0.6c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.3c0.1,0.3,0.4,0.7,1,1.4
	c0.5,0.7,1.3,1,2.3,1.1c0.3,0,0.4,0.1,0.4,0.2c0,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0c-0.3,0-0.6,0-1,0l-1.8,0l0,2.2
	c0,0.4,0,0.6-0.1,0.6c-0.3,0-0.6-0.1-0.7-0.2C508.1,463.1,508.1,462.2,508,460.8z M509,467.2l-2.5,0l-2.4,0h-1.8l-1.4,0
	c-0.5,0-0.9,0.1-1,0.2c-0.1,0.2-0.2,0.4-0.2,0.7c0,0.4-0.1,0.6-0.3,0.6c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0-0.1,0-0.2
	c0.1-0.6,0.1-1.2,0.1-1.8c0-0.9,0-1.5-0.1-1.8c0-0.1,0-0.1,0-0.2c0-0.2,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.3,0.6
	c0.1,0.4,0.1,0.6,0.3,0.7c0.1,0.1,0.5,0.2,1.1,0.2l4.7,0.1c1.2,0,2-0.1,2.2-0.3c0.2-0.2,0.4-0.5,0.4-1c0-0.2,0.1-0.2,0.2-0.2
	c0.1,0,0.1,0,0.2,0.1c0,0.1,0.1,0.5,0.1,1.2C509,466.4,509,466.9,509,467.2z M514.5,466.1c0,0.3-0.1,0.5-0.3,0.7
	c-0.2,0.2-0.4,0.3-0.7,0.3c-0.3,0-0.5-0.1-0.8-0.2c-0.2-0.2-0.3-0.4-0.3-0.7c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.4-0.3,0.7-0.3
	c0.3,0,0.6,0.1,0.8,0.3C514.4,465.6,514.5,465.8,514.5,466.1z M502.3,475.8c-0.9,0-1.6-0.3-2.2-0.9c-0.6-0.6-0.9-1.3-0.9-2.1
	c0-0.5,0.2-1,0.5-1.7c0.2-0.4,0.3-0.6,0.3-0.6c0-0.1-0.1-0.2-0.2-0.3c-0.2-0.1-0.3-0.2-0.3-0.3c0-0.1,0.1-0.1,0.3-0.2
	c0.2,0,0.7,0,1.5,0c0.7,0,1.1,0,1.3,0c0.3,0,0.4,0.1,0.4,0.2c0,0.1-0.1,0.1-0.2,0.2c-0.9,0.3-1.6,0.6-2.2,1.1
	c-0.6,0.5-0.9,1-0.9,1.7c0,0.5,0.2,0.9,0.5,1.2c0.3,0.4,0.7,0.5,1.3,0.5c0.8,0,1.6-0.6,2.2-1.9c0.4-0.9,0.8-1.5,1-1.8
	c0.2-0.3,0.5-0.5,0.8-0.7c0.3-0.2,0.7-0.3,1.1-0.3c0.8,0,1.4,0.3,1.9,0.8c0.5,0.5,0.8,1.2,0.8,1.9c0,0.6-0.2,1.2-0.6,1.8
	c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.2,0.1,0.2,0.2c0,0.1,0,0.1-0.1,0.1c-0.1,0-0.4,0.1-1,0.1
	c-0.6,0-1.1,0.1-1.5,0.1c-0.2,0-0.4-0.1-0.4-0.2c0-0.1,0.1-0.2,0.3-0.2c0.2-0.1,0.6-0.2,1.2-0.5c0.6-0.3,0.9-0.6,1.1-0.9
	c0.2-0.3,0.2-0.6,0.2-0.9c0-0.5-0.1-0.9-0.4-1.2c-0.3-0.3-0.6-0.5-1-0.5c-0.3,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.2-0.5,0.4
	c-0.1,0.1-0.5,0.8-1,1.9c-0.4,0.7-0.7,1.2-0.8,1.4c-0.2,0.2-0.5,0.4-0.8,0.6C503,475.8,502.7,475.8,502.3,475.8z M509,479.9
	c-0.3,0.1-0.6,0.1-0.8,0.1c-0.4,0-0.7,0-1.1,0c0.6,0.5,1.1,1,1.4,1.5c0.3,0.5,0.5,1,0.5,1.6c0,0.5-0.1,0.9-0.4,1.3
	c-0.3,0.4-0.6,0.8-1.2,1.1c0.5,0.5,0.9,1,1.2,1.5c0.3,0.5,0.4,1,0.4,1.5c0,0.7-0.2,1.2-0.5,1.6c-0.3,0.4-0.6,0.7-1,0.8
	c-0.4,0.1-1,0.2-2,0.2l-4.2,0c-0.6,0-1,0.1-1.3,0.2c-0.2,0.1-0.3,0.3-0.3,0.7c0,0.2-0.1,0.3-0.2,0.3c-0.1,0-0.2-0.2-0.2-0.6v-3.1
	c0-0.3,0.1-0.5,0.2-0.5c0.1,0,0.2,0.1,0.2,0.3c0,0.6,0.2,0.9,0.4,1c0.3,0.1,1.6,0.2,4.1,0.2c1.2,0,2,0,2.5-0.1
	c0.5-0.1,0.9-0.3,1.2-0.6c0.3-0.3,0.5-0.6,0.5-1.1c0-0.4-0.1-0.7-0.4-1.1c-0.3-0.4-0.6-0.7-1.1-1c-1-0.3-2.7-0.4-5.2-0.4
	c-0.9,0-1.4,0.1-1.7,0.2c-0.2,0.1-0.3,0.4-0.3,0.8v0.2c0,0.2-0.1,0.3-0.2,0.3c-0.1,0-0.2-0.1-0.2-0.4V483c0-0.3,0.1-0.5,0.2-0.5
	c0.1,0,0.2,0.1,0.2,0.3c0,0.4,0.1,0.7,0.3,0.8c0.2,0.1,0.7,0.2,1.6,0.3c0.9,0,1.8,0.1,2.7,0.1c1.6,0,2.7-0.1,3.2-0.4
	c0.5-0.3,0.8-0.7,0.8-1.2c0-0.5-0.2-0.9-0.6-1.4c-0.4-0.5-1-0.8-1.6-0.9c-0.7-0.2-1.9-0.2-3.7-0.2c-1.2,0-2,0.1-2.3,0.2
	c-0.3,0.1-0.4,0.4-0.4,0.8c0,0.2,0,0.3,0,0.3c0,0-0.1,0.1-0.2,0.1c-0.1,0-0.2-0.1-0.2-0.4v-3.1c0-0.4,0.1-0.6,0.2-0.6
	c0.1,0,0.2,0.1,0.2,0.2c0,0.4,0.1,0.6,0.2,0.7c0.1,0.1,0.5,0.2,1.2,0.3c0.7,0.1,1.8,0.1,3.3,0.1c1.5,0,2.5,0,2.8,0
	c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.9c0-0.2,0.1-0.3,0.2-0.3c0.1,0,0.1,0,0.2,0.1c0,0,0.1,0.4,0.1,0.9
	c0.1,0.6,0.1,1.1,0.1,1.5C509,479.5,509,479.7,509,479.9z M477.7,370.7c0-0.3,0-0.5,0-0.6c0-0.3,0-0.7,0-1.4c0-0.7,0-2,0-4.1v-2.6
	c0-0.1,0-0.4-0.1-1c0-0.6-0.1-1-0.1-1.1c0-0.5,0.1-0.8,0.2-0.8c0.1,0,0.2,0.1,0.2,0.3c0,0.7,0.2,1.1,0.5,1.4
	c0.3,0.3,0.6,0.4,1.1,0.5c0.5,0.1,1.9,0.1,4.1,0.1l4,0c1.3,0,2.2-0.1,2.7-0.2c0.5-0.1,0.9-0.3,1.2-0.6c0.3-0.3,0.4-0.7,0.5-1.1
	c0-0.2,0.1-0.3,0.2-0.3c0.1,0,0.2,0.1,0.2,0.3c0,0,0,0.1,0,0.3c-0.1,1.1-0.1,2.3-0.1,3.8l0,1.8c0,0.2-0.1,0.3-0.2,0.3
	c-0.1,0-0.2-0.1-0.2-0.3c0-0.5-0.1-0.9-0.3-1.2c-0.2-0.3-0.5-0.5-0.7-0.6c-0.3-0.1-0.8-0.2-1.6-0.2l-5.3-0.1l-3,0
	c-0.9,0-1.5,0-1.8,0.1c-0.3,0.1-0.5,0.2-0.7,0.4c-0.2,0.2-0.3,0.6-0.3,1.2c0,0,0,0.7,0.1,1.8c0.1,0.9,0.6,1.8,1.5,2.5
	c0.9,0.7,1.6,1.2,2.3,1.5c0.2,0.1,0.3,0.2,0.3,0.3c0,0.1-0.1,0.2-0.3,0.2c-0.2,0-1-0.1-2.3-0.3C478.6,370.8,477.9,370.7,477.7,370.7
	z M487.4,374.6l-2.5,0l-2.4,0h-1.8l-1.4,0c-0.5,0-0.9,0.1-1,0.2c-0.1,0.2-0.2,0.4-0.2,0.7c0,0.4-0.1,0.6-0.3,0.6
	c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0-0.1,0-0.2c0.1-0.6,0.1-1.2,0.1-1.8c0-0.9,0-1.5-0.1-1.8c0-0.1,0-0.1,0-0.2c0-0.2,0.1-0.2,0.2-0.2
	c0.1,0,0.2,0.2,0.3,0.6c0.1,0.4,0.1,0.6,0.3,0.7c0.1,0.1,0.5,0.2,1.1,0.2l4.7,0.1c1.2,0,2-0.1,2.2-0.3c0.2-0.2,0.4-0.5,0.4-1
	c0-0.2,0.1-0.2,0.2-0.2c0.1,0,0.1,0,0.2,0.1c0,0.1,0.1,0.5,0.1,1.2C487.4,373.8,487.4,374.3,487.4,374.6z M492.9,373.5
	c0,0.3-0.1,0.5-0.3,0.7c-0.2,0.2-0.4,0.3-0.7,0.3c-0.3,0-0.5-0.1-0.8-0.2c-0.2-0.2-0.3-0.4-0.3-0.7c0-0.3,0.1-0.5,0.3-0.7
	c0.2-0.2,0.4-0.3,0.7-0.3c0.3,0,0.6,0.1,0.8,0.3C492.8,373,492.9,373.2,492.9,373.5z M479.7,381.8c3.6,1.5,5.8,2.2,6.5,2.2
	c0.3,0,0.5-0.2,0.6-0.6c0-0.2,0.1-0.3,0.2-0.3c0.1,0,0.2,0.1,0.2,0.3l0,1.5l0,0.9c0,0.2-0.1,0.3-0.2,0.3c-0.1,0-0.2-0.1-0.2-0.2
	c-0.1-0.6-0.3-1-0.8-1.2c-0.5-0.3-1.3-0.6-2.5-1c-2-0.7-3.4-1.2-4.2-1.6c-0.7-0.3-1.2-0.5-1.4-0.6c-0.3-0.1-0.4-0.2-0.4-0.3
	c0-0.1,0.2-0.2,0.5-0.3l2.9-1.2l3.1-1.3c1.1-0.4,1.8-0.7,2.1-0.9c0.3-0.2,0.5-0.3,0.6-0.4c0.1-0.1,0.1-0.3,0.2-0.5
	c0-0.2,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.3l0,1.8c0,0.8,0,1.3,0,1.4c0,0.2-0.1,0.4-0.2,0.4c-0.1,0-0.2-0.1-0.2-0.4
	c-0.1-0.3-0.3-0.5-0.6-0.5C485.7,379.4,483.5,380.2,479.7,381.8z M487.4,389.6l-2.5,0l-2.4,0h-1.8l-1.4,0c-0.5,0-0.9,0.1-1,0.2
	c-0.1,0.2-0.2,0.4-0.2,0.7c0,0.4-0.1,0.6-0.3,0.6c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0-0.1,0-0.2c0.1-0.6,0.1-1.2,0.1-1.8
	c0-0.9,0-1.5-0.1-1.8c0-0.1,0-0.1,0-0.2c0-0.2,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.3,0.6s0.1,0.6,0.3,0.7c0.1,0.1,0.5,0.2,1.1,0.2
	l4.7,0.1c1.2,0,2-0.1,2.2-0.3c0.2-0.2,0.4-0.5,0.4-1c0-0.2,0.1-0.2,0.2-0.2c0.1,0,0.1,0,0.2,0.1c0,0.1,0.1,0.5,0.1,1.2
	C487.4,388.8,487.4,389.3,487.4,389.6z M492.9,388.5c0,0.3-0.1,0.5-0.3,0.7c-0.2,0.2-0.4,0.3-0.7,0.3c-0.3,0-0.5-0.1-0.8-0.2
	c-0.2-0.2-0.3-0.4-0.3-0.7c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.4-0.3,0.7-0.3c0.3,0,0.6,0.1,0.8,0.3C492.8,388,492.9,388.2,492.9,388.5
	z M487.4,394.3c-0.4,0.2-1.2,0.3-2.2,0.3c0.7,0.4,1.3,0.9,1.7,1.4c0.4,0.5,0.6,1.1,0.6,1.7c0,0.5-0.1,0.9-0.3,1.3
	c-0.2,0.4-0.5,0.8-1,1c-0.5,0.2-1.3,0.4-2.4,0.4l-3.8-0.1c-0.7,0-1.1,0.1-1.3,0.2c-0.2,0.1-0.3,0.3-0.4,0.7
	c-0.1,0.2-0.2,0.4-0.2,0.4c-0.1,0-0.2-0.1-0.2-0.2c0,0,0-0.1,0-0.1c0.1-0.3,0.1-0.8,0.1-1.4c0-0.5,0-1.1-0.1-1.8
	c0-0.2,0.1-0.4,0.2-0.4c0.1,0,0.1,0.1,0.2,0.2c0.1,0.4,0.2,0.6,0.3,0.7c0.1,0.1,0.2,0.2,0.4,0.2c0.2,0,0.7,0.1,1.6,0.1
	c0.9,0,1.7,0,2.3,0c1.6,0,2.6-0.2,3.2-0.5c0.5-0.4,0.8-0.8,0.8-1.3c0-0.8-0.5-1.4-1.4-2c-1-0.5-2.5-0.8-4.8-0.8
	c-1,0-1.6,0.1-1.9,0.2c-0.3,0.1-0.4,0.4-0.5,0.9c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2l0-0.5c0-0.2,0-0.8,0-1.9l0-0.8
	c0-0.3,0.1-0.4,0.2-0.4c0.1,0,0.2,0.2,0.3,0.5c0.1,0.3,0.2,0.5,0.4,0.6c0.2,0.1,0.7,0.2,1.7,0.2c1,0,2.1,0.1,3.6,0.1
	c1.3,0,2.2-0.1,2.5-0.2c0.3-0.2,0.5-0.4,0.6-0.7c0.1-0.3,0.2-0.4,0.3-0.4c0.1,0,0.2,0.1,0.2,0.3l0,0.8
	C487.4,393,487.4,393.5,487.4,394.3z M485.8,409.7c-0.5,0.3-1.1,0.5-1.8,0.5c-1,0-1.8-0.3-2.5-0.9c-0.7-0.6-1.1-1.4-1.2-2.5
	c-0.1-0.7-0.1-1.2-0.2-1.4c-0.1-0.3-0.2-0.5-0.4-0.7c-0.2-0.2-0.4-0.3-0.6-0.3c-0.3,0-0.5,0.2-0.7,0.5c-0.2,0.3-0.2,1-0.3,2
	c-0.1,1.5-0.1,2.5-0.2,3c-0.1,0.5-0.3,0.9-0.8,1.3c-0.4,0.4-1,0.5-1.7,0.5c-1.1,0-2.1-0.5-2.8-1.4c-0.7-0.9-1.1-2.2-1.1-4
	c0-1.3,0.2-2.3,0.6-3c0.4-0.7,0.9-1,1.7-1c1.2,0,2.1,1,2.9,3c0.1-0.8,0.4-1.4,0.6-1.8c0.3-0.3,0.6-0.5,1.1-0.5
	c0.5,0,0.9,0.2,1.3,0.5c0.4,0.4,0.7,0.9,0.9,1.6c0.2-0.6,0.6-1.2,1.1-1.6c0.5-0.4,1.1-0.6,1.9-0.6c1,0,1.9,0.4,2.7,1.1
	c0.8,0.7,1.2,1.6,1.2,2.7c0,1.1-0.4,1.9-1.2,2.6c0.9,0.7,1.3,1.3,1.3,2c0,0.4-0.1,0.8-0.4,1c-0.3,0.2-0.6,0.4-0.9,0.4
	c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.3,0.2-0.6,0.7-0.8c0.3-0.1,0.4-0.3,0.4-0.4
	C486.6,410.5,486.3,410.2,485.8,409.7z M476.7,407.5c0-0.7-0.1-1.4-0.4-2c-0.3-0.6-0.6-1-0.9-1.3c-0.4-0.3-0.8-0.4-1.3-0.4
	c-0.7,0-1.2,0.3-1.5,0.9c-0.3,0.6-0.5,1.3-0.5,2.2c0,1.3,0.3,2.3,0.9,2.9c0.6,0.6,1.2,1,1.9,1c0.6,0,1.1-0.3,1.4-0.8
	C476.5,409.3,476.7,408.5,476.7,407.5z M483.9,404.8c-1.1,0-1.9,0.2-2.4,0.7c-0.5,0.4-0.7,0.9-0.7,1.4c0,0.5,0.2,0.9,0.7,1.2
	c0.5,0.4,1.2,0.5,2.2,0.5c1,0,1.8-0.2,2.4-0.5c0.6-0.4,0.8-0.8,0.8-1.3c0-0.6-0.3-1-0.8-1.4C485.6,404.9,484.9,404.8,483.9,404.8z
	 M485.2,421.9l-2.8,0c-1.9,0-3,0-3.3,0.1c-0.2,0.1-0.5,0.2-0.7,0.3c-0.2,0.2-0.4,0.6-0.4,1.1c0,0.3-0.1,0.5-0.2,0.5
	c-0.2,0-0.2-0.1-0.2-0.3c0-0.1,0-0.3,0.1-0.6c0.1-0.7,0.1-1.3,0.1-1.9c0-0.5,0-1.1-0.1-1.9l0-0.6c0-0.2,0.1-0.3,0.2-0.3
	c0.2,0,0.2,0.2,0.2,0.5c0,0.4,0.1,0.7,0.4,0.9c0.3,0.2,1,0.3,2.1,0.4c1.1,0.1,2.6,0.1,4.4,0.1c1.7,0,3.1,0,4.1-0.1
	c1-0.1,1.6-0.1,1.9-0.2c0.3-0.1,0.5-0.2,0.7-0.4c0.1-0.2,0.2-0.6,0.3-1.1c0-0.2,0.1-0.3,0.2-0.3c0.1,0,0.2,0.1,0.2,0.3
	c0.2,1.7,0.2,4.9,0.2,9.6c0,0.8,0,1.2,0,1.3c0,0.1-0.1,0.1-0.1,0.2c-0.1,0-0.6,0.1-1.6,0.3c-1,0.2-1.5,0.2-1.5,0.2
	c-0.2,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.3c1.8-1,2.8-2.5,2.8-4.6c0-0.7,0-1.3-0.1-1.8c-0.1-0.5-0.2-0.7-0.3-0.8
	c-0.1-0.1-0.6-0.1-1.4-0.1c-0.8,0-2.3,0-4.4,0c0,0.5,0,0.9,0,1.1c0,1,0.2,1.7,0.7,2c0.5,0.4,0.9,0.6,1.4,0.6c0.3,0,0.5,0.1,0.5,0.2
	c0,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0-0.3,0c-0.4-0.1-1.5-0.1-3.3-0.1h-1.3c-0.3,0-0.5-0.1-0.5-0.3c0-0.1,0.1-0.2,0.3-0.2
	c0.7,0,1.2-0.2,1.7-0.6c0.4-0.4,0.6-1.1,0.6-2.2C485.3,422.6,485.3,422.3,485.2,421.9z M483,431c-0.4,0-0.7,0-0.9,0
	c-1.1,0-2,0.3-2.7,0.8c-0.7,0.6-1.1,1.3-1.1,2.1c0,0.8,0.4,1.6,1.1,2.3c0.2,0.2,0.3,0.3,0.3,0.4c0,0.1-0.1,0.2-0.3,0.2
	c-0.5-0.3-1-0.7-1.3-1.3c-0.4-0.6-0.5-1.3-0.5-2.1c0-1.1,0.4-2.1,1.3-2.9c0.9-0.8,2-1.2,3.4-1.2c1.5,0,2.7,0.4,3.7,1.3
	c1,0.9,1.5,1.8,1.5,2.7c0,0.9-0.4,1.7-1.2,2.5c-0.8,0.7-1.7,1.1-2.7,1.1c-0.2,0-0.3,0-0.4-0.1c-0.1,0-0.1-0.3-0.1-0.8L483,431z
	 M483.6,431c0,0.9-0.1,1.7-0.1,2.6l0,0.5c0,0.4,0.1,0.7,0.3,0.9c0.2,0.2,0.4,0.3,0.9,0.3c0.6,0,1.2-0.2,1.7-0.6
	c0.5-0.4,0.7-0.9,0.7-1.4c0-0.5-0.3-1-0.8-1.5C485.7,431.4,484.8,431.1,483.6,431z M479.2,442.9c-0.5-0.3-0.9-0.7-1.2-1.2
	c-0.3-0.5-0.4-1-0.4-1.6c0-0.7,0.2-1.2,0.6-1.6c0.4-0.4,0.9-0.6,1.6-0.6c0.5,0,1,0.1,1.4,0.4c0.4,0.2,0.7,0.5,0.9,0.8
	c0.2,0.3,0.4,0.8,0.7,1.6c0.4,1.1,0.7,1.7,1,1.9c0.3,0.2,0.6,0.3,1,0.3c0.7,0,1.2-0.1,1.6-0.4c0.4-0.3,0.6-0.7,0.6-1.2
	c0-0.4-0.1-0.7-0.3-1c-0.2-0.2-0.6-0.4-1.3-0.5c-0.7-0.1-1-0.4-1-0.8c0-0.1,0.1-0.3,0.2-0.5c0.2-0.2,0.4-0.3,0.7-0.3
	c0.6,0,1.1,0.3,1.5,0.8c0.4,0.6,0.6,1.3,0.6,2.3c0,0.8-0.1,1.4-0.4,1.9c-0.2,0.5-0.5,0.8-0.8,0.9c-0.3,0.1-0.9,0.2-1.8,0.2l-3-0.1
	c-1.2,0-2,0.1-2.5,0.2c-0.5,0.1-0.7,0.4-0.7,0.7c0,0.1,0,0.2,0,0.3l0,0.2c0,0.2-0.1,0.2-0.2,0.2c-0.3,0-0.4-0.7-0.4-2
	c0-0.4,0.1-0.6,0.3-0.8C478.1,443,478.5,442.9,479.2,442.9z M483.6,442.9c-0.3-0.2-0.6-0.7-1.1-1.4c-0.4-0.7-0.8-1.2-1.2-1.5
	c-0.4-0.3-0.8-0.5-1.4-0.5c-0.5,0-0.9,0.1-1.2,0.4s-0.5,0.6-0.5,1c0,0.4,0.1,0.8,0.4,1.1c0.3,0.3,0.6,0.6,1,0.8
	c0.4,0.2,1,0.2,1.8,0.2C482.2,443,482.8,443,483.6,442.9z M480.7,452.9c-0.9,0-1.6-0.3-2.2-0.9c-0.6-0.6-0.9-1.3-0.9-2.1
	c0-0.5,0.2-1,0.5-1.7c0.2-0.4,0.3-0.6,0.3-0.6c0-0.1-0.1-0.2-0.2-0.3c-0.2-0.1-0.3-0.2-0.3-0.3c0-0.1,0.1-0.1,0.3-0.2
	c0.2,0,0.7,0,1.5,0c0.7,0,1.1,0,1.3,0c0.3,0,0.4,0.1,0.4,0.2c0,0.1-0.1,0.1-0.2,0.2c-0.9,0.3-1.6,0.6-2.2,1.1
	c-0.6,0.5-0.9,1-0.9,1.7c0,0.5,0.2,0.9,0.5,1.2c0.3,0.4,0.7,0.5,1.3,0.5c0.8,0,1.6-0.6,2.2-1.9c0.4-0.9,0.8-1.5,1-1.8
	c0.2-0.3,0.5-0.5,0.8-0.7c0.3-0.2,0.7-0.3,1.1-0.3c0.8,0,1.4,0.3,1.9,0.8c0.5,0.5,0.8,1.2,0.8,1.9c0,0.6-0.2,1.2-0.6,1.8
	c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.2,0.1,0.2,0.2c0,0.1,0,0.1-0.1,0.1c-0.1,0-0.4,0.1-1,0.1
	c-0.6,0-1.1,0.1-1.5,0.1c-0.2,0-0.4-0.1-0.4-0.2c0-0.1,0.1-0.2,0.3-0.2c0.2-0.1,0.6-0.2,1.2-0.5c0.6-0.3,0.9-0.6,1.1-0.9
	c0.2-0.3,0.2-0.6,0.2-0.9c0-0.5-0.1-0.9-0.4-1.2c-0.3-0.3-0.6-0.5-1-0.5c-0.3,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.2-0.5,0.4
	c-0.1,0.1-0.5,0.8-1,1.9c-0.4,0.7-0.7,1.2-0.8,1.4c-0.2,0.2-0.5,0.4-0.8,0.6C481.4,452.8,481.1,452.9,480.7,452.9z M486.4,457.4
	c-1.7-0.1-3.3-0.1-4.9-0.1c-1.2,0-2,0.1-2.4,0.4c-0.4,0.3-0.6,0.6-0.6,1.1c0,0.4,0.1,0.8,0.4,1.2c0.1,0.2,0.2,0.3,0.2,0.3
	c0,0.1-0.1,0.1-0.2,0.1c-0.2,0-0.5-0.2-0.9-0.7c-0.4-0.5-0.6-1.1-0.6-1.8c0-0.7,0.3-1.2,0.8-1.6c0.5-0.3,1.3-0.5,2.4-0.5
	c1.1,0,3,0.1,5.7,0.2c0,0,0-0.4,0.1-1l0-0.6c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.3c0.1,0.3,0.4,0.7,1,1.4
	c0.5,0.7,1.3,1,2.3,1.1c0.3,0,0.4,0.1,0.4,0.2c0,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0c-0.3,0-0.6,0-1,0l-1.8,0l0,2.2
	c0,0.4,0,0.6-0.1,0.6c-0.3,0-0.6-0.1-0.7-0.2C486.6,459.7,486.5,458.8,486.4,457.4z"/>
<path id="_x36_" class="st19 op" d="M468.3,481v1.9h4.9l0,3.1h-4.7c-0.1,0-0.2,0.1-0.2,0.3v18.2c0,0.2,0.1,0.3,0.2,0.3h2v2.4h-6.9v-2.8
	h2.6c0.2,0,0.3-0.1,0.3-0.3v-17.8c0-0.1-0.1-0.2-0.3-0.3h-2.6v-3.2h2.9V481h-10.6c-0.4,0-0.6,0.2-0.6,0.7v26.7
	c0,0.5,0.2,0.7,0.6,0.7h14.6h1.6h0.2v-4.3h3.5v4.3h1.4h0.4h4h0.6h1.2v-26.2v-0.6V481H468.3z M464.5,488c0.1,0,0.1,0,0.1,0.1v14.4
	c0,0.1-0.1,0.1-0.1,0.2h-3.8c0,0,0,0-0.1-0.1V488c0,0,0,0,0.1-0.1H464.5z M461.7,507.2h-4.5c-0.2,0-0.3-0.1-0.3-0.3v-23.7
	c0-0.2,0.1-0.3,0.3-0.3h4.5v3.2h-2.6c-0.1,0-0.2,0.1-0.2,0.3v17.8c0,0.2,0.1,0.3,0.2,0.3h2.6V507.2z M470,488
	C470,487.9,470,487.9,470,488l8.1-0.1c0.1,0,0.1,0,0.1,0.1v10.5h-5.1v-1.9h3.8l0-5.9h-7V488z M470,502.9
	C470,502.9,470,502.9,470,502.9l-0.1-10.3h5.1v1.9h-3.8l0,5.9l7,0v2.3c0,0.1,0,0.1-0.1,0.2H470z M481.5,507.2h-4v-2.4h2.2
	c0.2,0,0.3-0.1,0.3-0.3v-18.2c0-0.1-0.1-0.2-0.3-0.3h-4.7l0-3.1h6.5V507.2z"/>
</svg>
        </div>
        <img src="./s1/mo/text1.png" alt="雙語校園｜無瑕景觀｜ 豐居久安" class="img bottom_img text_img" data-aos="fade" data-aos-delay="900" />
        <img src="./s1/mo/title.png" alt="早鳥豐收價 24.8萬起" class="img titleimg" />
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.logo-icon {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  .st0{clip-path:url(#SVGID_2_);}
  .st0 path{fill:none;stroke:url(#img_3);stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
  .st11 path{fill:none;stroke:url(#img_2);stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
  .st1{stroke-width:20;}
  .st2{stroke-width:16;}
  .st3{stroke-width:12;}
  .st4{stroke-width:11;}
  .st5{stroke-width:18;}
  .st6{stroke-width:29;}
  .st7{stroke-width:14;}
  .st8{stroke-width:22;}
  .st9{stroke-width:19;}
  .st10{stroke-width:15;}
  .st11{clip-path:url(#SVGID_4_);}
  .st12{stroke-width:42;}
  .st13{stroke-width:38;}
  .st14{stroke-width:27;}
  .st15{stroke-width:31;}
  .op8{opacity:0.8;clip-path:url(#SVGID_6_);fill:none;stroke:#717272;stroke-width:50;stroke-linecap:round;stroke-miterlimit:10;}
  .st17{fill:#3E3A39;}
  .st18{fill:#717071;}
  .st19{fill:#BB121A;}
  .ld1{stroke-dasharray: 85;stroke-dashoffset: 85;   animation: letterDraw .2s linear forwards;}
  .ld2{stroke-dasharray:153;stroke-dashoffset: 153;   animation: letterDraw .6s .2s linear forwards;}
  .ld3{stroke-dasharray: 63;stroke-dashoffset: 63;   animation: letterDraw .3s .8s linear forwards;}
  .ld4{stroke-dasharray: 75;stroke-dashoffset: 75;   animation: letterDraw .2s 1.1s linear forwards;}
  .ld5{stroke-dasharray: 27;stroke-dashoffset: 27;   animation: letterDraw .1s 1.3s linear forwards;}
  .ld6{stroke-dasharray: 65;stroke-dashoffset: 65;   animation: letterDraw .3s 1.4s linear forwards;}
  .ld7{stroke-dasharray: 73;stroke-dashoffset: 73;   animation: letterDraw .3s 1.7s linear forwards;}
  .ld8{stroke-dasharray:185;stroke-dashoffset:185;   animation: letterDraw .5s 2s linear forwards;}
  .ld9{stroke-dasharray: 77;stroke-dashoffset: 77;   animation: letterDraw .3s 2.5s linear forwards;}
  .ld10{stroke-dasharray: 285;stroke-dashoffset: 285;   animation: letterDraw .5s 2.8s linear forwards;}
  .ld11{stroke-dasharray: 20;stroke-dashoffset:20;   animation: letterDraw .1s 3.3s linear forwards;}
  .ld12{stroke-dasharray: 42;stroke-dashoffset: 42;   animation: letterDraw .1s 3.4s linear forwards;}
  .ld13{stroke-dasharray: 52;stroke-dashoffset: 52;   animation: letterDraw .3s 3.5s linear forwards;}
  .ld14{stroke-dasharray: 13;stroke-dashoffset: 13;   animation: letterDraw .1s 3.8s linear forwards;}
  .ld15{stroke-dasharray: 72;stroke-dashoffset: 72;   animation: letterDraw .1s 3.9s linear forwards;}
  .ld16{stroke-dasharray: 185;stroke-dashoffset: 185;   animation: letterDraw .3s 4s linear forwards;}
  .ld17{stroke-dasharray: 80;stroke-dashoffset: 80;   animation: letterDraw .3s 4.3s linear forwards;}
  .ld18{stroke-dasharray: 70;stroke-dashoffset: 70;   animation: letterDraw .1s 4.6s linear forwards;}
  .ld19{stroke-dasharray: 135;stroke-dashoffset: 135;   animation: letterDraw .3s 4.7s linear forwards;}
  .ld20{stroke-dasharray: 175;stroke-dashoffset: 175;   animation: letterDraw .2s 5s linear forwards;}
  .ld21{stroke-dasharray: 45;stroke-dashoffset: 45;   animation: letterDraw .1s 5.2s linear forwards;}
  .ld22{stroke-dasharray: 115;stroke-dashoffset:115;   animation: letterDraw .3s 5.3s linear forwards;}
  .ld23{stroke-dasharray: 110;stroke-dashoffset: 110;   animation: letterDraw .2s 5.6s linear forwards;}
  .ld24{stroke-dasharray: 90;stroke-dashoffset: 90;   animation: letterDraw .3s 5.8s linear forwards;}
  .ld25{stroke-dasharray: 260;stroke-dashoffset: 260;   animation: letterDraw .3s 6.1s linear forwards;}
  .op{opacity: 0;animation: op .2s 6.1s forwards;}
  @keyframes letterDraw {
  to { stroke-dashoffset: 0;}
  }
  @keyframes op {
  to { opacity: 1;}
  }
}
</style>
<style lang="scss" scoped>
.bg {
  background-size: cover;
  position: relative;
  height: 100vh;
}

.img {
  width: 100vw;
  height: auto;
  position: absolute;
  left: 0;
  top: 0;
  object-fit: cover;
  display: block;
  &.fix {
    position: fixed;animation: imgmix 1.5s 5s forwards;opacity: 0;  top: 50%;
  transform: translateY(-47%);
  }

  &.mix {
    mix-blend-mode: multiply;opacity: 0;animation: imgmix 1.5s 3s forwards;
  top: 50%;
  transform: translateY(-47%);
  }
  &.titleimg{
	opacity: 0;animation: imgop 1.5s 3s forwards;
  top: 50%;
  transform: translateY(-50%);
  }
  &.bottom_img{top: auto;
	bottom: 0;}
  &.text_img{
	  opacity: 0;animation: imgop 1.5s 3s forwards;
  }
  &:nth-child(1) {
    position: relative;
  }
}


@keyframes imgop {
  to { opacity: 1;}
}
@keyframes imgmix {
  to { opacity: 1;
  transform: translateY(-50%);}
}

.girl2 {
  animation: girl 2s linear 2s infinite;
}

@keyframes girl {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* 平板尺寸 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

@media screen and (max-width: 767px) {
  .bg {
    background-size: cover;
  }
  
 .section1{
	 .bg {height: 169.3vw;overflow: hidden;}
	 }

  .img {
  &.fix {
  top: 0;
  transform: translateY(3%);
  }
	  &.mix {
  top: 0;
  transform: translateY(3%);
  }
    &:nth-child(1) {
      position: relative;
      height: calc(100vw * (636 / 375));
    }
  }
@keyframes imgmix {
  to { opacity: 1;
  transform: translateY(0%);}
}
}
</style>

<script>
// @ is an alias to /src
import { isMobile } from '@/utils'
import logo from './s1/logo.svg'

export default {
  name: 'section1',

  data() {
    return {
      isMobile,
      logo
    }
  },

  computed: {
  },

  methods: {},

  created() {},
  mounted() {
  },
}
</script>
